'use strict';
// Grab the base in integrations_core
var integrationsCore = require('integrations/product/base');
var exportDetails = {};
var scrollAnimate = require('base/components/scrollAnimate');
var manualUpdateCall = false;
var einsteinUtils = require('core/components/einsteinUtils');
var crossSell = require('../cart/crossSell');

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
 function updateAvailabilityProcess(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else if (!response.product.available ) {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';

			if (message == 'Pre-Order') {
				$('#preorder-pdp-callout-instock-date').html(response.product.availability.inStockDate);
				$('.show-preorder-callout').removeClass('d-none');
				$('.show-backorder-callout').addClass('d-none');
                message = $('.add-to-cart.btn').attr('data-add-to-cart-msg');
                $('.add-to-cart.btn').html(message);
            } else if (message == 'Back Order') {
				$('#backorder-pdp-callout-instock-date').html('&nbsp'+response.product.availability.inStockDate);
				$('.show-preorder-callout').addClass('d-none');
				$('.show-backorder-callout').removeClass('d-none');
                $('.add-to-cart.btn').html(message);                
            } else {
				$('#preorder-pdp-callout').addClass('d-none');
				var addToCartMsg = $('.add-to-cart.btn').data('add-to-cart-msg');
                $('.add-to-cart.btn').html(addToCartMsg);
			}
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {boolean} autoUpdateCall - boolean flag to check if call came on page load for POD product
 */
function attributeSelect(selectedValueUrl, $productContainer, autoUpdateCall) {
    //PAP-3529 - start: MS Round - Mutiples not going to the cart correctly
    var isPODProduct = $('.product-detail.bundle-item.custom-bundle').length > 0 && $('input[type="radio"][name="gripSetup"]').length > 0;
    if (isPODProduct) {
        //skip auto pageload call if user updated PDP manually
        if (autoUpdateCall && manualUpdateCall) {
            return;
        } else if (!autoUpdateCall) {
            manualUpdateCall = true;
        }
    }
    //PAP-3529 - end
    var methods = this;
    var selectedCardType;
    var selectedMMSwatchColor;
    var response;
    if ($productContainer.hasClass('mix-and-match-container')) {
        var $mmCard = $productContainer.find('.card-wrapper.mix-and-match-wrapper .collapse-area.show');
        selectedCardType = $mmCard.closest('.card-wrapper.mix-and-match-wrapper').attr('data-attrvalue');
        var selectedBaseType = '';
        if (selectedCardType === 'mm-MagsafeBaseWithTop') {
            selectedBaseType = 'MagSafePopGripRound';
            var selectedMMSwatch = $mmCard.find('.mm-swatch .selected');
            selectedMMSwatchColor = selectedMMSwatch.closest('button.mm-swatch').attr('data-value');
        } else if (selectedCardType === 'mm-AdhesiveBaseWithTop') {
            selectedBaseType = 'AdhesiveBase';
            var selectedMMSwatch = $mmCard.find('.mm-swatch .selected');
            selectedMMSwatchColor = selectedMMSwatch.closest('button.mm-swatch').attr('data-value');
        } else if (selectedCardType === 'mm-topOnly') {
            selectedBaseType = 'none';
            var url = new URL(selectedValueUrl);
            var searchParams = url.searchParams;
            var pid = searchParams.get('pid');
            searchParams.set('dwvar_' + pid + '_baseColor', 'None');
            searchParams.set('dwvar_' + pid + '_baseType', 'none');
            url.search = searchParams.toString();
            selectedValueUrl = url.toString();
        }
        selectedValueUrl+='&selectedBaseType='+selectedBaseType;
        selectedValueUrl+='&selectedMMSwatchColor='+selectedMMSwatchColor
    }
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        var bundleDetailData = $('.product-detail.bundle-item.custom-bundle').length ? $('.product-detail.bundle-item.custom-bundle').data() : null;
        // attribute being selected on a custom bundle pass the bundle id in
        // the call
        if (bundleDetailData && bundleDetailData.hasOwnProperty('bundleid')) {
            selectedValueUrl += '&bundleid=' + bundleDetailData.bundleid;
        }

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                response = data;
                // PAP-4802 - updte variant url
                if (data.variantUpdatedUrl && !$('.bundle-item').length && !$('.custom-bundle').length) {
                    window.history.pushState({ path: data.variantUpdatedUrl, variantUrlUpdated: true }, '', data.variantUpdatedUrl);
                }

                //PAP-4849 - yotpo widget update
                if (!$('.bundle-item').length && !$('.custom-bundle').length && $('body #yotpo-reviews-top-div').length && $('body #yotpo-bottomline-top-div').length && data.product && data.product.id && typeof yotpo !== 'undefined') {
                    $('body #yotpo-reviews-top-div').attr('data-product-id', data.product.id);
                    $('body #yotpo-bottomline-top-div').attr('data-product-id', data.product.id);
                    yotpo.refreshWidgets();
                }
                //PAP-4220 - updating product ID on clect of swatch
                $('.oosNotifyMeEmail').attr("data-productId", data.product.id)
                //PAP-3529 - start: MS Round - Mutiples not going to the cart correctly
                if (isPODProduct && autoUpdateCall && manualUpdateCall) {
                    //if AJAX response came from auto page load call and manual call is also triggered don't update PDP
                    return;
                }
                //PAP-3529 - end
                methods.handleVariantResponse(data, $productContainer);
                if ($('.product-detail.bundle-item.custom-bundle').length && $('input#MagSafeBase').is(':checked')) {
                    $('.bundle-items .primary-images').addClass('magsafe-grips-slider-images');
                } else {
                    $('.bundle-items .primary-images').removeClass('magsafe-grips-slider-images');
                }
                methods.updateOptions(data.product.optionsHtml, $productContainer);
                methods.updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $.spinner().stop();
                if ($productContainer.hasClass('mix-and-match-container')) {
                    if (!selectedCardType) {
                        var url = new URL(selectedValueUrl);
                        var pid = url.searchParams.get('pid');
                        var baseType = url.searchParams.get('dwvar_' + pid + '_baseType');
                        var cardType;
                        if (baseType === 'MagSafePopGripRound') {
                            cardType = 'mm-MagsafeBaseWithTop'
                        } else if (baseType === 'AdhesiveBase') {
                            cardType = 'mm-AdhesiveBaseWithTop'
                        }
                        if (cardType === 'mm-MagsafeBaseWithTop' || cardType === 'mm-AdhesiveBaseWithTop') {
                            if (cardType === 'mm-MagsafeBaseWithTop' && !response.product.isPopTopProductAvailable) {
                                cardType = 'mm-AdhesiveBaseWithTop';
                            }
                            if ($productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="' + cardType + '"] .mm-swatch').length) {
                                $productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="' + cardType + '"] .mm-swatch')[0].click();
                            }
                        } else {
                            var $mmCard = $productContainer.find('.card-wrapper.mix-and-match-wrapper .collapse-area.show');
                            cardType = $mmCard.closest('.card-wrapper.mix-and-match-wrapper').attr('data-attrvalue');
                            if (cardType && cardType !== 'mm-topOnly' && $productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="' + cardType + '"] .mm-swatch').length) {
                                $productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="' + cardType + '"] .mm-swatch')[0].click();
                            }
                        }
                    } else if (selectedCardType !== 'mm-topOnly') {
                        if ($productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="' + selectedCardType + '"] .mm-swatch[data-value="' + selectedMMSwatchColor + '"]').length) {
                            $productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="' + selectedCardType + '"] .mm-swatch[data-value="' + selectedMMSwatchColor + '"]').trigger('click');
                        } else if ($productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="' + selectedCardType + '"] .mm-swatch').length)  {
                            $productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="' + selectedCardType + '"] .mm-swatch')[0].click();
                        } else if ($productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="mm-AdhesiveBaseWithTop"] .mm-swatch').length) {
                            $productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="mm-AdhesiveBaseWithTop"] .mm-swatch')[0].click();
                        }
                    } else if (selectedCardType === 'mm-topOnly' ) {
                        if (!response.product.isPopTopProductAvailable) {
                            if ($productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="mm-AdhesiveBaseWithTop"] .mm-swatch').length) {
                                $productContainer.find('.card-wrapper.mix-and-match-wrapper[data-attrvalue="mm-AdhesiveBaseWithTop"] .mm-swatch')[0].click();
                            }
                        } else {
                            $('.product-detail.mix-and-match-container .primary-images').find('img.overlay-image').addClass('d-none');
                        }
                    }
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

$(window).on('popstate', function(event) {
    // PAP-4802 - reload page if 'variantUrlUpdated' property exist. To update PDP.
    if (event.originalEvent && event.originalEvent.state && event.originalEvent.state.variantUrlUpdated) {
        location.reload();
    }
});

/**
 * updates the mix and match information when attribute is selected or deselected
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {jQuery} $swatchContainer - DOM element for current swatches
 * @param {jQuery} $cardWrapper - DOM element for current section
 */
function mmAttributeSelect(selectedValueUrl, $productContainer, $swatchContainer, $cardWrapper) {
    var methods = this;
    var selectedCardType;
    var selectedMMSwatchColor;
    var selectedBaseType = '';
    var selectedType;
    if ($cardWrapper) {
        selectedCardType = $cardWrapper.attr('data-attrvalue');
        selectedMMSwatchColor = $swatchContainer.find('button[data-url="' + selectedValueUrl + '"]').attr('data-value');
        selectedType = $swatchContainer.find('button[data-url="' + selectedValueUrl + '"]').attr('data-attr');
        if (selectedCardType === 'mm-MagsafeBaseWithTop') {
            selectedBaseType = 'MagSafePopGripRound';
        } else if (selectedCardType === 'mm-AdhesiveBaseWithTop') {
            selectedBaseType = 'AdhesiveBase';
        }
        selectedValueUrl+='&selectedBaseType='+selectedBaseType;
        selectedValueUrl+='&selectedMMSwatchColor='+selectedMMSwatchColor
    }

    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                if (selectedType === 'baseColor') { //when selected varient is finish good
                    selectedCardType = $cardWrapper.attr('data-attrvalue');
                    if (selectedCardType === 'mm-MagsafeBaseWithTop') {
                        data.product.images = data.mixAndMatchMagsafeBase.images;
                    } else if (selectedCardType === 'mm-AdhesiveBaseWithTop') {
                        data.product.images = data.mixAndMatchAdhesiveBase.images;
                    }
                }
                methods.mmHandleVariantResponse(data, $productContainer, $swatchContainer);
                if ($cardWrapper && $cardWrapper.data('attrvalue') == 'mm-MagsafeBaseWithTop' && $cardWrapper.hasClass('selected')) {
                    $('.mix-and-match-container .primary-images').addClass('magsafe-grips-slider-images');
                } else {
                    $('.mix-and-match-container .primary-images').removeClass('magsafe-grips-slider-images');
                }
                methods.updateOptions(data.product.optionsHtml, $productContainer);
                methods.updateQuantities(data.product.quantities, $productContainer);
                if ($cardWrapper && data.product.images && data.product.images.large && data.product.images.large.length) {
                    $cardWrapper.find('.img-part img').first().attr('src', data.product.images.large[0].url);
                }
                //Card Type could be change after ajax response, when moving from card with sold out item to poptop card
                var $mmCard = $productContainer.find('.card-wrapper.mix-and-match-wrapper .collapse-area.show');
                selectedCardType = $mmCard.closest('.card-wrapper.mix-and-match-wrapper').attr('data-attrvalue');
                if (selectedCardType === 'mm-topOnly') {
                    $('.product-detail.mix-and-match-container .primary-images').find('img.overlay-image').addClass('d-none');
                }
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
	if (!response.error && response.sfmcBasketInfo && typeof _etmc !== 'undefined' && _etmc) {
		_etmc.push(["trackCart", response.sfmcBasketInfo]);
    }
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        this.chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if (response.error) {
            if ($('.add-to-cart-messages').length === 0) {
                $('body').append(
                    '<div class="add-to-cart-messages"></div>'
                );
            }
            $('.add-to-cart-messages').append(
                '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
                + response.message
                + '</div>'
            );
            setTimeout(function () {
                $('.add-to-basket-alert').remove();
            }, 5000);
        } else {
            var url = $('.minicart').data('action-url');
            $('.minicart .popover').addClass('show');
            setTimeout(function () {
                $('.minicart .popover').spinner().start();
            }, 50);
            $.get(url, data => {
                if($('.minicart').hasClass('flyout-enabled')) {
                    $('.minicart.flyout-enabled .minicart-link').click();
                    $('.minicart.flyout-enabled [data-flyout-wrapper]').empty();
                    $('.minicart.flyout-enabled [data-flyout-wrapper]').append(data);
                    crossSell.init();
                    $('body').trigger('minicart:reinitPaypal');
                }
                else {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    $.spinner().stop();
                    $('body').trigger('minicart:loaded', $('.minicart .popover'));
                }
            });
        }
    }
}

/**
 * Process the mix and match base attribute values for an attribute
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $swatchContainer - DOM container for a given swatch list
 * @param {Object} msgs - object containing resource messages
 */
function mmProcessSwatchValues(attr, $swatchContainer, msgs) {
    if (!$swatchContainer) {
        return;
    }
    $swatchContainer.find('button.mm-swatch').each(function(){
        $(this).removeClass('selected');
        $(this).find("span.Color-value.swatch-value").removeClass('selected');
    });

    attr.values.forEach(function (attrValue) {
        var $attrValue = $swatchContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');

        var $swatchButton = $attrValue.parent('button');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            $attrValue.attr('selected', 'selected');
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
            $attrValue.removeAttr('selected');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable available unavailable out-of-stock');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable out-of-stock');
        $attrValue.addClass(attrValue.available ? 'available' : '');

        $attrValue.attr('value', attrValue.url).removeAttr('disabled');

        if (!attrValue.selectable) {
            $swatchButton.parent('.swatch-tile-container').addClass('unselectable');
        } else {
            $swatchButton.parent('.swatch-tile-container').removeClass('unselectable');
        }

        if ($swatchButton.hasClass('color-attribute')) {
            if (attrValue.selectable) {
                $swatchButton.removeClass('unselectable-btn');
            } else {
                $swatchButton.addClass('unselectable-btn');
            }
        }
    });
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
 function processSwatchValues(attr, $productContainer, msgs) {
    if (attr.attributeId == 'color' || attr.attributeId == 'customColor') {
        $productContainer.find('.color-display-value').text(attr.displayValue || '');
    };

    if (attr.attributeId == 'size') {
        $productContainer.find('.non-color-display-value').text(attr.displayValue || '');
    };

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');

        var $swatchButton = $attrValue.parent('button');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            $attrValue.attr('selected', 'selected');
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
            $attrValue.removeAttr('selected');
        }
        //PAP-2108 start: don't update selected value url and url for mix and match bases.
        if (!attrValue.selected) {
            if (attrValue.url) {
                $swatchButton.attr('data-url', attrValue.url);
            } else {
                $swatchButton.removeAttr('data-url');
            }
        }
        //PAP-2108 end

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable available unavailable out-of-stock');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable out-of-stock');
        $attrValue.addClass(attrValue.available ? 'available' : '');

        $attrValue.attr('value', attrValue.url).removeAttr('disabled');

        if (!attrValue.selectable) {
            $swatchButton.parent('.swatch-tile-container').addClass('unselectable');
        } else {
            $swatchButton.parent('.swatch-tile-container').removeClass('unselectable');
        }

        //PAP-2311 start: handle orderable and non-orderable color swatches
        if ($swatchButton.hasClass('color-attribute')) {
            if (attrValue.selectable) {
                $swatchButton.removeClass('unselectable-btn');
            } else {
                $swatchButton.addClass('unselectable-btn');
            }
        }
        //PAP-2384 end
    });

    if($productContainer.find('.swatches, .swatches-holder').length && $productContainer.closest('.byo.byo-bundle').length == 0) {
        var attrIdElem = '#' + attr.id;
        var $elementToBeSelected = $productContainer.find('.swatches button[data-attr-displayvalue="' + attr.displayValue + '"], ' + attrIdElem + ' .swatches-holder button[data-attr-displayvalue="' + attr.displayValue + '"]');
        if($elementToBeSelected && $elementToBeSelected.length) {
            $productContainer.find('.swatches button, .swatches button input').removeClass('selected');
            $productContainer.find(attrIdElem + ' .swatches-holder button.swatch, ' + attrIdElem + ' .swatches-holder button.swatch span.swatch-value').removeClass('selected');
            $elementToBeSelected.addClass('selected');
            $elementToBeSelected.find('input, span.swatch-value').addClass('selected');
        }
    }
    // PAP-3089: Remove the selected class for non color attribute swatches
    if($productContainer.find('.non-color-attribute-swatches').length && $productContainer.closest('.byo.byo-bundle').length == 0) {
        var $elementToBeSelected = $productContainer.find('.non-color-attribute-swatches button[data-attr-displayvalue="' + attr.displayValue + '"]');
        if($elementToBeSelected && $elementToBeSelected.length) {
            //PAP-3663 - Fixed selection is removed on variant change
            $elementToBeSelected.closest('.non-color-attribute-swatches').find('button.swatch, .swatch span.non-color-swatch-value').removeClass('selected');
            $elementToBeSelected.addClass('selected');
            $elementToBeSelected.find('span.non-color-swatch-value').addClass('selected');
        }
    }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
 function processNonSwatchValues(attr, $productContainer) {
    var $attr = '.custom-select[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url);
        $attrValue.attr('disabled', false);
    });

    $('.custom-select option:selected').removeAttr('selected');
    $($attr + " option:contains('" + attr.displayValue + "')")
    .filter(function(i){
        return $(this).data('attr-displayvalue') === attr.displayValue;
    })
    .prop('selected', true);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    if (response.product.variationAttributes) {
        this.updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    if (response.product.HideOosVariant) {
        $('.product-wrapper .product-data').addClass('hide-oos-variants');
    } else {
        $('.product-wrapper .product-data').removeClass('hide-oos-variants');
    }

    // Start PAP-4734
    if (response.product.pdpChipBadges) {
        $('.chip-badges-area').empty().html(response.product.pdpChipBadges);
        $('.chip-badges-area').removeClass('d-none');
    } else {
        $('.chip-badges-area').empty();
        $('.chip-badges-area').addClass('d-none');
    }
    // End PAP-4734

    if ($('.ootb-bundle').length > 0) {
        $productContainer.attr('data-producttype', response.product.productType);
    }

    if ($('.byo-bundle').length > 0) {
        $('.ootb-bundle.js-bundle-images.selected').removeClass('selected');
        var container = '.ootb-bundle-selection-'+$productContainer.attr('data-sid');
        $(container).closest('.ootb-bundle.js-bundle-images').addClass('selected');
        var selection = '';
        var popTopDesign = '';
        
        if (response.product.variationAttributes) {

            popTopDesign = $('#popTopDesignSelection').text();
            $(container).empty();
            response.product.variationAttributes.forEach(function (attr) {
                attr.values.forEach(function (attrValue) {
                    if (attrValue.selected) {
                        var addClass = attr.id !== 'iPhoneType' ? 'd-none' : '';
                        selection += '<p class="'+addClass+'">'+ attrValue.displayValue +'</p>';
                    }
                });
            });
            
            $(container).append(selection);
        }
        $('.byo-bundle').attr('showPopTopDesigns', response.product.custom.showPopTopDesigns);

        if (response.product.custom.showPopTopDesigns) {
            $(container).append('<p id="popTopDesignSelection" class="d-none">'+ popTopDesign +'</p>');
            $productContainer.find('.pop-top-design input').each( function(item) {
                var recipeID = response.product.recipeIDsMapping[response.product.id + '_' + $(this).attr('popTopSKU')] ? response.product.recipeIDsMapping[response.product.id + '_' + $(this).attr('popTopSKU')] : '';
                $(this).attr('recipeID', recipeID);
            });
        } else if (response.product.popTopDesignVaritionAttributes && $('.product-bundle').attr('data-is-byo-bundle') == 'true') {
            updatePopTopDesign(response.product.popTopDesignVaritionAttributes[0]);
        }
    }
    //PAP-2311: handle add to cart and notify me section for selected variation
    if (isVariant && response.product && response.product.available) {
        $productContainer.find('#notify-me-section').addClass('d-none');
        $productContainer.find('.add-to-cart, .add-to-cart-global').removeClass('disabled');
    } else {
        /**
         * PAP-3089: added check to show form OOS incase of OOS product selection and also update the
         * form fields for current selected variant
        */ 
        $productContainer.find('#notify-me-section').removeClass('d-none');
        $productContainer.find('.add-to-cart, .add-to-cart-global').addClass('disabled');
    }

    // PAP-1526: PDP name for new Master SKUs is not changing based on variant selection
    if(response && response.product && response.product.productName && response.product.productName !== '' && !response.product.custom.componentOfCustomBundle){
        // PAP-2249: Adding em dash to product names and parsing as line break
        var productNameArr = response.product.productName.split('—');
        $productContainer.find('.product-name').html(productNameArr[0]);
        if(productNameArr.length > 1) {
            $productContainer.find('.product-name-row-2').html(productNameArr[1]);
            $productContainer.find('.product-name-row-2').removeClass('d-none');
        } else {
            $productContainer.find('.product-name-row-2').html('');  
            $productContainer.find('.product-name-row-2').addClass('d-none');                     
        }
    }

    //PAP-1742: update collapsable content PDP
    if(response.product.collapsibleContentHtml) {
        $productContainer.find('.main-content-item').empty().html(response.product.collapsibleContentHtml);
        $productContainer.find('.main-content-item-new').empty().html(response.product.collapsibleContentHtmlNew);
    }

    // PAP-2257: PDP - Variation product Add-Ons
    $productContainer.find('#product-addons-recommendations').empty();  
    if(response.product.recommendations.length > 0) {
        $productContainer.find('#product-addons-recommendations').html(response.product.recommendationsHTML);  
        $productContainer.find('#product-addons-recommendations').removeClass('d-none'); 
    }

    // PAP-4239: SFCC development for the Mix and Match feature      
    if(response.product.mixAndMatchAccordianHTML && response.product.mixAndMatchAccordianHTML.length > 0) {
        var productFeatures = $('.mix-and-match-wrapper .product-feature-container').html();
        $productContainer.find('#mixandmatchaccordion').empty();
        $productContainer.find('#mixandmatchaccordion').html(response.product.mixAndMatchAccordianHTML);  
        $productContainer.find('#mixandmatchaccordion').removeClass('d-none');
        $('.product-detail.product-wrapper.mix-and-match').attr('data-pid', $('input[name=mixAndMatchFinishgoodSku]').val());
        $('.product-detail.product-wrapper.mix-and-match').data('pid', $('input[name=mixAndMatchFinishgoodSku]').val());
        $('.mix-and-match-wrapper .product-feature-container').html(productFeatures);

        if ($('.card-wrapper.mix-and-match-wrapper[data-attrvalue="mm-MagsafeBaseWithTop"]').hasClass('selected')) {
            $('.mix-and-match-container .primary-images').addClass('magsafe-grips-slider-images');
        } else {
            $('.mix-and-match-container .primary-images').removeClass('magsafe-grips-slider-images');
        }
    }

    if (response.product.mixAndMatchStyleImages && response.product.mixAndMatchStyleImages.large && response.product.mixAndMatchStyleImages.large.length > 0) {
        $('.product-detail.mix-and-match-container').attr('data-firststyleimg', response.product.mixAndMatchStyleImages.large[0].url);
        $('.product-detail.mix-and-match-container').data('firststyleimg', response.product.mixAndMatchStyleImages.large[0].url);
    } else {
        $('.product-detail.mix-and-match-container').attr('data-firststyleimg', '');
        $('.product-detail.mix-and-match-container').data('firststyleimg', '');
    }

    if (response.product.mixAndMatchStyleImages && response.product.mixAndMatchStyleImages.large && response.product.mixAndMatchStyleImages.large.length > 1) {
        $('.product-detail.mix-and-match-container').attr('data-secondstyleimg', response.product.mixAndMatchStyleImages.large[1].url);
        $('.product-detail.mix-and-match-container').data('secondstyleimg', response.product.mixAndMatchStyleImages.large[1].url); 
    } else {
        $('.product-detail.mix-and-match-container').attr('data-secondstyleimg', '');
        $('.product-detail.mix-and-match-container').data('secondstyleimg', '');
    }

    if (response.product.mixAndMatchStyleImages && response.product.mixAndMatchStyleImages.large && response.product.mixAndMatchStyleImages.large.length > 2) {
        $('.product-detail.mix-and-match-container').attr('data-thirdstyleimg', response.product.mixAndMatchStyleImages.large[2].url);
        $('.product-detail.mix-and-match-container').data('thirdstyleimg', response.product.mixAndMatchStyleImages.large[2].url); 
    } else {
        $('.product-detail.mix-and-match-container').attr('data-thirdstyleimg', '');
        $('.product-detail.mix-and-match-container').data('thirdstyleimg', '');
    }

    if (response.product.mixAndMatchStyleImages && response.product.mixAndMatchStyleImages.large && response.product.mixAndMatchStyleImages.large.length > 3) {
        $('.product-detail.mix-and-match-container').attr('data-fourthstyleimg', response.product.mixAndMatchStyleImages.large[3].url);
        $('.product-detail.mix-and-match-container').data('fourthstyleimg', response.product.mixAndMatchStyleImages.large[3].url); 
    } else {
        $('.product-detail.mix-and-match-container').attr('data-fourthstyleimg', '');
        $('.product-detail.mix-and-match-container').data('fourthstyleimg', '');
    }

    if (response.product.isMixAndMatchBaseAvailable) {
        $productContainer.find('.add-to-cart, .add-to-cart-global').text($('input[name=addToCartText]').val());
        $productContainer.find('.add-to-cart, .add-to-cart-global').removeClass('disabled');
    }

    if (response.product.isMixAndMatchDisableAddToCart) {
        $productContainer.find('.add-to-cart, .add-to-cart-global').text($('input[name=soldOutText]').val());
        $productContainer.find('.add-to-cart, .add-to-cart-global').addClass('disabled');
    }

    // PAP-2017: Notify Me email form does not appear on combined variant/master PDPs when assigned at the variant level
    if(response && response.product && response.product.klaviyobackinstockform && !response.product.available){
        $productContainer.find('.notify-bis').attr("data-target", response.product.klaviyobackinstockform);
        $productContainer.find('.bis-container').removeClass('d-none');
        $productContainer.find('.bis-container').show();
    } else {
        $productContainer.find('.bis-container').hide();
    }

    //Reset Klarna display
    if (document.querySelector('klarna-placement') && response.product.price && response.product.price.sales){
        var price = response.product.price.sales.decimalPrice;
        price = price.replace(".","");
        var minAmt = $('.klarna-msg').attr('data-min-amt');
        if (response.product.price.sales.value > minAmt) {
            $('.klarna-msg').removeClass('d-none'); 
        } else {
            $('.klarna-msg').addClass('d-none'); 
        }
         
        document.querySelector('klarna-placement').dataset.purchaseAmount = parseInt(price,10);
        window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
        window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' }); 
    }

    // Update primary images
    var primaryImages = response.product.images;
    var $oldWishlistIcon = $productContainer.find('.product-data .attributes a.wishlist');
    if ($productContainer.hasClass('mix-and-match-container')) {
        var $mmCard = $productContainer.find('.card-wrapper.mix-and-match-wrapper .collapse-area.show');
        var selectedCardType = $mmCard.closest('.card-wrapper.mix-and-match-wrapper').attr('data-attrvalue');
        if (!selectedCardType) {
            primaryImages = response.mixAndMatchAdhesiveBase.images;
            this.createSlider(primaryImages, $productContainer);
        } else if ( selectedCardType === 'mm-topOnly') {
            this.createSlider(primaryImages, $productContainer);
        }
    } else {
        this.createSlider(primaryImages, $productContainer);
    }
    
    var $badgesContainer = $productContainer.find('.badge-product-container');
    if (response.product.badges && response.product.badges.length) {
        if (!$badgesContainer.length) {
            $badgesContainer = $('<div class="badge-product-container badge-image-over"></div>').html($('<span class="badge-product "'+ response.product.badges[0].class +'></span>'));
        }
        $badgesContainer.find('span').html(response.product.badges[0].name);
        $badgesContainer.removeClass('d-none');
    } else if ($badgesContainer.length) {
        $badgesContainer.addClass('d-none');
    }

    if($('body').find("#a2c-version-3").length > 0 && primaryImages.large && primaryImages.large.length) {
        $('#a2c-version-3 .prod-image img').attr("src", primaryImages.large[0].url);
        $('#a2c-version-3 .prod-image img').attr("alt", primaryImages.large[0].alt);
        $('#a2c-version-3 .prod-name').text($('.product-detail-attributes').find('.attribute-option.color-swatches .color-display-value').text());
        $('#a2c-version-3 .prices .price').replaceWith(response.product.price.html);
    }

    if($('body').find("#a2c-version-2").length > 0) {
        $('#a2c-version-2 .prices .price').replaceWith(response.product.price.html);
    }
    
    
    var $badgesContainer = $productContainer.find('.badge-product-container');
    if (response.product.badges && response.product.badges.length) {
        if (!$badgesContainer.length) {
            $badgesContainer = $('<div class="badge-product-container badge-image-over"></div>').html($('<span class="badge-product "'+ response.product.badges[0].class +'></span>'));
        }
        $badgesContainer.find('span').html(response.product.badges[0].name);
        $badgesContainer.removeClass('d-none');
    } else if ($badgesContainer.length) {
        $badgesContainer.addClass('d-none');
    }

    // Update pricing
    if (!isChoiceOfBonusProducts && $('.ootb-bundle').length == 0) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }
    //PAP-2572: update price in paypal element on variant update
    if ($('.pp-msg').length && $('.ootb-bundle').length == 0) {
        $('.pp-msg').attr('data-pp-amount', response.product.price.sales.value);
    }
    // Update details for paired product
    if ($('.pdp-paired-products-buttons').length > 0) {
        var currency = response.product.price.sales.currency;
        var basePrice = response.product.price.sales.decimalPrice;
        var curSymbol = $('.pdp-paired-products-buttons').data('currencysymbol');

        // update price
        $('.pdp-paired-products-buttons').attr('data-pricedecimal', basePrice);
        $('.pdp-paired-products-buttons').attr('data-priceformatted', response.product.price.sales.formatted);

        if ($('.pdp-paired-button-values').html().length > 0) {
            var extraTopBasePrice = $('.paired-buttons-result-list button.btn.selected').data('baseprice');
            var newPriceDecimal = (parseFloat(basePrice) + parseFloat(extraTopBasePrice));
            $('.paired-buttons-result-list button.btn').attr('data-pricedecimal', newPriceDecimal);
            $('.paired-buttons-result-list button.btn').attr('data-priceformatted', curSymbol + newPriceDecimal);
        }

        // update url
        var baseUrl = $('.pdp-paired-products-buttons').data('baseurl');
        baseUrl+='&baseprice='+basePrice+'&currencycode='+currency;

        $('.pdp-paired-products-buttons').attr('data-url', baseUrl);
    }

    // Update promotions
    if (response.product.promotionsHtml && response.product.promotions && response.product.promotions.length > 0) {
        if (response.product.promotionsHtml.trim() !== '') {
            $productContainer.find('.promotions').removeClass('d-none');
            $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);
        }
    } else {
        $productContainer.find('.promotions').addClass('d-none');
    }
    this.updateAvailabilityProcess(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    if ($('.bundle-items').length === 0) {
        // Update attributes
        $productContainer.find('.main-attributes').empty().html(this.getAttributesHtml(response.product.attributes));
        $productContainer.find('.js-product-headline').empty().html(response.product.headline);
        this.updateBadges($productContainer, response.product.badges);
    }

    // Update wishlist
    if ($oldWishlistIcon && $oldWishlistIcon.length) {
        var $newWishlistIcon = $($oldWishlistIcon[0]);
        $newWishlistIcon.attr('data-wishlistpid', response.product.wishlistpid);

        //Make heart icon accurate
        var wishlist = require('core/wishlist/wishlist.js');
        wishlist.updateWishlistLinkData($newWishlistIcon);

        var $newSliderMainImages = $productContainer.find('div.primary-images-main div.slide img');
        $newSliderMainImages.each((_i, newImage) => {
            var $newImage = $(newImage);
            $newImage.after($newWishlistIcon.clone(true));
        });
    }
}

/**
 * Routes the handling of mix and match base attribute processing 
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID

 * @param {jQuery} $swatchContainer - DOM element for a selected swatch list
 * @param {Object} msgs - object containing resource messages
 */
function mmUpdateAttrs(attrs, msgs, $swatchContainer) {
    attrs.forEach(function (attr) {
        mmProcessSwatchValues(attr, $swatchContainer, msgs);
    });
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 * @param {jQuery} $swatchContainer - DOM element for a swatches.
 */
function mmHandleVariantResponse(response, $productContainer, $swatchContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    if (response.product.variationAttributes) {
        mmUpdateAttrs(response.product.variationAttributes, response.resources, $swatchContainer);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    if (response.product.popTopAssignedVariant || response.product.magsafeAssignedVariant || response.product.adhesiveAssignedVariant) {
        $('.product-detail.product-wrapper.mix-and-match').attr('data-pid', response.product.id);
        $('.product-detail.product-wrapper.mix-and-match').data('pid', response.product.id);
        $('input[name=mixAndMatchSelectedSkus]').val('');
    } else {
        $('input[name=mixAndMatchSelectedSkus]').val(response.product.id);
        $('.product-detail.product-wrapper.mix-and-match').attr('data-pid', $('input[name=mixAndMatchPopTopSku]').val());
        $('.product-detail.product-wrapper.mix-and-match').data('pid', $('input[name=mixAndMatchPopTopSku]').val());
    }
    
    //PAP-2311: handle add to cart and notify me section for selected variation
    if (isVariant && response.product && response.product.available) {
        $productContainer.find('#notify-me-section').addClass('d-none');
        $productContainer.find('.add-to-cart, .add-to-cart-global').removeClass('disabled');
        $productContainer.find('.add-to-cart, .add-to-cart-global').text($('input[name=addToCartText]').val());
    } else {
        /**
         * PAP-3089: added check to show form OOS incase of OOS product selection and also update the
         * form fields for current selected variant
        */ 
        $productContainer.find('#notify-me-section').removeClass('d-none');
        $productContainer.find('.add-to-cart, .add-to-cart-global').addClass('disabled');
        $productContainer.find('.add-to-cart, .add-to-cart-global').text($('input[name=soldOutText]').val());
    }

    // PAP-2017: Notify Me email form does not appear on combined variant/master PDPs when assigned at the variant level
    if(response && response.product && response.product.klaviyobackinstockform && !response.product.available){
        $productContainer.find('.notify-bis').attr("data-target", response.product.klaviyobackinstockform);
        $productContainer.find('.bis-container').removeClass('d-none');
        $productContainer.find('.bis-container').show();
    } else {
        $productContainer.find('.bis-container').hide();
    }

    //Reset Klarna display
    if (document.querySelector('klarna-placement') && response.product.price && response.product.price.sales){
        var price = response.product.price.sales.decimalPrice;
        price = price.replace(".","");
        var minAmt = $('.klarna-msg').attr('data-min-amt');
        if (response.product.price.sales.value > minAmt) {
            $('.klarna-msg').removeClass('d-none'); 
        } else {
            $('.klarna-msg').addClass('d-none'); 
        }
         
        document.querySelector('klarna-placement').dataset.purchaseAmount = parseInt(price,10);
        window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
        window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' }); 
    }

    // Update primary images
    var primaryImages = response.product.images;
    var $oldWishlistIcon = $productContainer.find('.product-data .attributes a.wishlist');
    this.createSlider(primaryImages, $productContainer);

    var $badgesContainer = $productContainer.find('.badge-product-container');
    if (response.product.badges && response.product.badges.length) {
        if (!$badgesContainer.length) {
            $badgesContainer = $('<div class="badge-product-container badge-image-over"></div>').html($('<span class="badge-product "'+ response.product.badges[0].class +'></span>'));
        }
        $badgesContainer.find('span').html(response.product.badges[0].name);
        $badgesContainer.removeClass('d-none');
    } else if ($badgesContainer.length) {
        $badgesContainer.addClass('d-none');
    }

    // Update promotions
    if (response.product.promotionsHtml && response.product.promotions && response.product.promotions.length > 0) {
        $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);
        $productContainer.find('.promotions').removeClass('d-none');
    } else {
        $productContainer.find('.promotions').addClass('d-none');
    }
    this.updateAvailabilityProcess(response, $productContainer);

    // Enable "Add to Cart" button if all required attributes have been selected
    $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
        product: response.product, $productContainer: $productContainer
    }).trigger('product:statusUpdate', response.product);

    if (response.product.template === 'mixandmatch_v1' ) {
        if(response.product.collapsibleContentHtml) {
            if ($productContainer.find('.main-content-item').length > 0) {
                $productContainer.find('.main-content-item').empty().html(response.product.collapsibleContentHtml);
            } else {
                $productContainer.find('.main-content-group').append($(response.product.collapsibleContentHtml));
            }
        }
    }
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    if ($productContainer.find('.product-options').length > 0) {
	// Update options on PDP
        $productContainer.find('.product-options').empty().html(optionsHtml);
    } else if ($productContainer.find('.product-option').length > 0) {
        //update options dropdown in edit product popUp
        $productContainer.find('.product-option').empty().html(optionsHtml);
        updateSelectedOption($productContainer);
    } else if ($productContainer.find('#cross-sell-target').length > 0) {
        //add options dropdown in edit product popUp, if no option product selected previously
        $productContainer.find('#cross-sell-target').before(optionsHtml);
        updateSelectedOption($productContainer);
    }
}

/**
 * Updates selected option ID in updateProduct URL on edit product popup
 *
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateSelectedOption($productContainer) {
    var selectedOptionValueId = $productContainer.find('.options-select').children('option:selected').data('value-id');
    $('.modal.show .update-cart-url').data('selected-option-value-id', selectedOptionValueId);
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
 function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').eq(0).text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10) || 1
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

function updateBadges($productContainer, badges) {
    var $badgeProduct = $productContainer.find('.badge-product');
    $badgeProduct.empty();
    for (let i = 0; i < badges.length; i++) {
        var badge = badges[i];
        var $badge = $('<div>').addClass('badge-product').addClass(badge.class);
        $badge.html(badge.name);
        $badgeProduct.append($badge);
    }
}

//PAP-1072 start: update color in url in case of missing accordian color for CYO product
if ($('.product-detail').attr('data-is-cyo') == 'true' && $('.select-accordioncolor').length > 0) {
    //update color only if have accordioncolor dropdown
    if ($('.select-accordioncolor').length > 0) {
        var selectedColor = $('.select-accordioncolor').find(':selected');
        if (selectedColor.length == 0 || selectedColor.val() == 'null') {
            //pick random color if no value selected
            selectedColor = $('.select-accordioncolor option[value!=null]');
        }
        if (selectedColor.length > 0) {
            var colorOption = selectedColor[0];
            var color = $(colorOption).attr('data-attr-value');
            var url = $(colorOption).attr('value');
            if (color.length > 0 && url.length > 0) {
                var urlParams = new URL($(colorOption).attr('value'));
                var productId = urlParams.searchParams.has('pid') ? urlParams.searchParams.get('pid') : '';
                if(productId.length > 0) {
                    var phoneOptions = $('.select-iPhoneType option');
                    $.map(phoneOptions ,function(option) {
                        if (option.value != 'null' && !option.value.includes('accordioncolor')) {
                            var optionUrl = new URL(option.value);
                            //append color queryparameter in url
                            optionUrl.searchParams.append('dwvar_'+ productId +'_accordioncolor', color);
                            option.value = optionUrl.toString();
                        }
                    });
                }
            }
        }
    }
}
//PAP-1072 End

function updateCartButton() {
    var isMaster = false;
    $('.bundle-item').each(function () {
        if ($(this).attr('data-producttype') == 'master' && $(this).attr('data-defaultvariantselected') == 'false') {
            isMaster = true;
            return false;
        }
    });
    //PAP-124 enable/disable add all to cart button
    var enable = $('.product-availability').toArray().every(function (item) {
        return $(item).data('available') && $(item).data('ready-to-order');
    });
    $('button.add-to-cart-global').attr('disabled', (!enable || isMaster));
    if(!enable || isMaster) {
        if($('.js-bundle').find('button.add-to-cart-global').length > 0) {
            var availableMsg = $(".availability.product-availability[data-available='false']").attr('data-available-status');
            $('.js-bundle').find('button.add-to-cart-global .action-msg').html(availableMsg);                    
            $('.js-bundle').find('button.add-to-cart-global').find('.price').hide();
        }
    }
    // PAP-3089: Hiding BIS form for BYO and Soft bundles
    if($(document).find('.ootb-bundle.bundle-items') && $(document).find('.ootb-bundle.bundle-items').length) {
        $('#notify-me-section').addClass('d-none');
    }
}

if ($('.ootb-bundle').length > 0) {
    updateCartButton();
    $('.sticky-images .primary-images').on('click',function () {
        $('.ootb-bundle.js-bundle-images.selected').removeClass('selected');
        $(this).closest('.ootb-bundle.js-bundle-images').addClass('selected');
        var pid = $(this).attr('data-pid');
        var pos = $('.product-detail[data-pid='+pid+']').offset();
        if (pos) {
            $('html,body').animate({ scrollTop: pos.top });
        }
    });
}

/**
 * Updates slider for PDP main images and thumbnails from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
 function createSlider(images, $productContainer) {
    var $sliderContainers = $productContainer.find('.slider-container');
    var data = images !== null ? {images} : null;

    if ($('.js-bundle-images').length > 0) {
        var bundlecontainerId = $sliderContainers.attr('data-slider-images');
        if (bundlecontainerId) {
            var $bundleSlider = $('.js-bundle-images').find('[data-slider-images='+ bundlecontainerId +']')
            $($bundleSlider.get().reverse()).each((index, sliderContainer) => {
                var $slider = $(sliderContainer).find('.slider');
                $slider.trigger('slider:update', data);
            });
        }
    }

    // Reversing order in which to update sliders so that thumbnails get initialized first
    $($sliderContainers.get().reverse()).each((index, sliderContainer) => {
        var $slider = $(sliderContainer).find('.slider');
        $slider.trigger('slider:update', data);
    });

    //PAP-4422 :Fixed single image functionality not working on variant update 
    var hasSingleImage = images && images['large'] && images['large'].length > 1 ? false : true;
    if (hasSingleImage) {
        $productContainer.find('.primary-images-thumbnails').removeClass('col-md-9 d-md-block');
        $productContainer.find('.primary-images-thumbnails').addClass('d-none');
    } else {
        $productContainer.find('.primary-images-thumbnails').addClass('col-md-9');
        $productContainer.find('.primary-images-main').addClass('product-has-thumbnails');
        $productContainer.find('.primary-images-thumbnails').removeClass('d-none');
        if ($productContainer.find('.primary-images-thumbnails').data('enablescrollslider')) {
            $productContainer.find('.primary-images-thumbnails').addClass('d-none d-md-block');
        }
    }
}

function updatePopTopDesign (attr) {
    var $productContainer = $('.byo-bundle');
    var isSelected = true;
    var selectedAttrValue = $productContainer.find('[data-attr="' + attr.id + '-pop-design"] input.selected ').data('attr-value');
    var isShow = selectedAttrValue ? attr.values.find(o => o.value === selectedAttrValue).isShow : false;

    for (let i = 0; i < attr.values.length; i++) {
        var attrValue = attr.values[i];
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '-pop-design"] [data-attr-value="' + attrValue.value + '"]');

        var $swatchButton = $attrValue.parent('button');

        $attrValue.removeClass('selected');
        $attrValue.siblings('.selected-assistive-text').empty();
        $attrValue.removeAttr('selected');
        
        if (attrValue.isShow) {
            if (isSelected && (!isShow || (isShow && selectedAttrValue === attrValue.value))) {
                isSelected = false;
                $attrValue.addClass('selected');
                $attrValue.attr('selected', 'selected');
                $productContainer.find('.pop-design-color-display-value').text(attrValue.displayValue || '');
                
                var src =  $productContainer.find('.pop-top-design button input.selected').attr('overlayimage');
                $productContainer.find('img.overlay-image').attr('src', src);
                $('#popTopDesignSelection').empty().html($productContainer.find('.pop-top-design button input.selected').data('attr-value'));
            }
            $swatchButton.removeClass('d-none');
        } else {
            $swatchButton.removeClass('d-none');
            $swatchButton.addClass('d-none');
        }
        
        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable available unavailable out-of-stock');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable out-of-stock');
        $attrValue.addClass(attrValue.available ? 'available' : '');

        if (!attrValue.selectable) {
            $swatchButton.parent('.swatch-tile-container').addClass('unselectable');
        } else {
            $swatchButton.parent('.swatch-tile-container').removeClass('unselectable');
        }

        $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    }
}

function processImages(views, suffix) {
    if (suffix == null) {
        suffix = '';
    }

    var image = {
        'expanded': '',
        'product': '',
        'oms': ''
    };

    if (views) {
        for (let i=0; i<views.length; i++) {
            var view = views[i];

            if (view.code.toLowerCase() === 'product' + suffix) {
                image['product'] = view.previewPng;
            }

            if (view.code.toLowerCase() === 'omsfront1' + suffix) {
                image['oms'] = view.previewPng;
            }
        }
    }

    return image;
}

function resetRecipeAttributes(){
    if ($('.pop-top-design').length && $('.byo-bundle').length) {
        $('.byo-bundle').data('is-add-recipe', true);
        $(".byo-bundle").attr('omsImage', '');
        $(".byo-bundle").attr('imageUrl', '');
    }
}

async function setRecipeData(recipeJsonFile) {
    let myInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        mode: 'cors'
    };
    let recipeJsonFileRequest = new Request(recipeJsonFile, myInit);
    
    fetch(recipeJsonFileRequest).then(function (results) {
        return results.json();
    }).then(function (recipeJsonFileData) {
        var customizeImages = processImages(recipeJsonFileData.views);
        var omsImage = customizeImages['oms'];

        if (customizeImages['product']) {
            omsImage = customizeImages['product'];
        }

        var imageUrl = [];
        for(var i=0; i < recipeJsonFileData.components.length; i++) {
            if(recipeJsonFileData.components[i].component.custom.hasOwnProperty('dynamic-image-jpgUrl')) {
                imageUrl.push(recipeJsonFileData.components[i].component.custom['dynamic-image-jpgUrl']);
            }
        }

        $('.byo-bundle').data('is-add-recipe', 'false');
        $(".byo-bundle").attr('omsImage', omsImage);
        $(".byo-bundle").attr('imageUrl', imageUrl.length > 0 ? JSON.stringify(imageUrl) : '');
        $('button.add-to-cart, button.add-to-cart-global').trigger('click');
    }).catch(function(){
        $('.byo-bundle').data('is-add-recipe', 'true');
        $(".byo-bundle").attr('omsImage', '');
        $(".byo-bundle").attr('imageUrl', '');
    });
}

//PAP-2780: Searchable if Unavailable PDP issue: update selected product details and change button text to Sold Out
function checkForClickableAttribute($attributeButtonElement) {
    return ($attributeButtonElement.attr('disabled') || $attributeButtonElement.data('url') === null || $attributeButtonElement.hasClass('selected'));
}

// Function to pust addToCart event in dataLayer
function addToCartEvent(eventID, eventBtn) {
    if ($('*[data-gtmcartuniqueid]').length && eventID) {
        $('*[data-gtmcartuniqueid]').data('gtmcartuniqueid', eventID);
    }
    var gtmData = eventBtn.data("gtmdata") || $.parseJSON(eventBtn.attr("data-gtmdata"));
    var qty = eventBtn.closest(".product-wrapper").find(".quantity-select").val();
    var obj = {
        quantity: qty
    };
    var gtmObj = {
        event: 'addToCart',
        ecommerce: {
            add: {
                products: []
            }
        }
    }
    if (eventID) {
        gtmObj.ecommerce.add.uniqueID = eventID;
    }
    gtmObj.ecommerce.add.products.push($.extend(gtmData, obj));
    window.dataLayer.push(gtmObj);
}

// extend override functionality for base.js
exportDetails = $.extend(true, {}, integrationsCore, {
    methods: {
        updateAvailabilityProcess: updateAvailabilityProcess,
        attributeSelect: attributeSelect,
        getChildProducts: getChildProducts,
        handlePostCartAdd: handlePostCartAdd,
        handleVariantResponse: handleVariantResponse,
        mmHandleVariantResponse: mmHandleVariantResponse,
        mmAttributeSelect: mmAttributeSelect,
        processSwatchValues: processSwatchValues,
        processNonSwatchValues: processNonSwatchValues,
        updateBadges: updateBadges,
        updateOptions: updateOptions,
        createSlider: createSlider
    },
    /**********
    * shared across QV and PDP
    */
    addToCart: function () {
        var scope = this;
        var isTouchDevice = 'ontouchstart' in document.documentElement;

        $(document).on(isTouchDevice ? 'touchstart' : 'click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            var eventBtn = $(this);
            if ($('.pop-top-design').length && $('.byo-bundle').length && $('.byo-bundle').data('is-byo-bundle') === true && $('.byo-bundle').data('is-add-recipe') === true) {
                var recipeID = $('.pop-top-design').find('input.selected').attr('recipeID');
                var recipeJsonFile = 'https://cz.drrv.co/recipe/' + recipeID + '.json';
                setRecipeData(recipeJsonFile);
                return true;
            }

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val() || 1,
                            options: scope.methods.getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = scope.methods.getPidValue($(this));

            if (pid && pid.toString().includes('MSMM') && $('.product-detail.product-wrapper.mix-and-match').length && $('input[name=mixAndMatchSelectedSkus]').length) {
                var pidArr = pid.split('_');
                if (pidArr.length > 2) {
                    pid = pidArr[1];
                    $('input[name=mixAndMatchSelectedSkus]').val(pidArr[2]);
                }
            }

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                var $productModalbody = $(this).closest('.modal-content');
                addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
            } else {
                addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
            }

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: scope.methods.getChildProducts(),
                quantity: scope.methods.getQuantitySelected($(this)),
                currentUrl: $(location).attr('href')
            };

            if (!$('.bundle-item').length) {
                form.options = scope.methods.getOptions($productContainer);
            }

            if ($('.pop-top-design').length && $('.byo-bundle').length) {
                if ($('.byo-bundle').data('is-byo-bundle') === true) {
                    form.recipeID = $('.pop-top-design').find('input.selected').attr('recipeID');
                    form.omsImage = $('.byo-bundle').attr('omsImage');
                    form.imageUrl = $('.byo-bundle').attr('imageUrl')
                } else {
                    var childProducts = JSON.parse(form.childProducts);
                    childProducts.push({
                        pid: $('.pop-top-design').find('input.selected').attr('popTopSKU'),
                        quantity: 1
                    });
                    form.childProducts = childProducts.length ? JSON.stringify(childProducts) : [];
                }
            }

            var canBeAdded = true;
            var mutuallyExclusiveProducts = $('.url-picker').data('mutually-exclusive-products');
            if(mutuallyExclusiveProducts){
                var url = $('.url-picker').data('product-canbeadded');
                $('.mutually-exclusive-products').addClass('d-none');
                $.ajax({
                    url: url+'?pid='+pid,
                    method: 'post',
                    success: function (data) {
                        canBeAdded = data.canBeAdded;
                        if(canBeAdded){
                            $(this).trigger('updateAddToCartFormData', form);
                            if (addToCartUrl) {
                                // check if this is a paired value
                                if ($('.paired-buttons-container').length > 0) {
                                    var pairedID = $('.paired-buttons-result-list button.selected').data('pid');
                                    if (pairedID == undefined) {
                                        pairedID = null;
                                    }
                                    form.pairedAction = true;
                                    form.pairedParent = pid;
                                    form.pairedChild = pairedID;
                                } else if ($('.product-detail.product-wrapper.mix-and-match').length && $('input[name=mixAndMatchSelectedSkus]').length && $('input[name=mixAndMatchSelectedSkus]').val() != '') {
                                    var pairedID = $('input[name=mixAndMatchSelectedSkus]').val();
                                    if (pairedID == undefined) {
                                        pairedID = null;
                                    }
                                    form.pairedAction = true;
                                    form.pairedParent = pid;
                                    form.pairedChild = pairedID;
                                }
                                form.currentUrl = $(location).attr('href');
                                $.ajax({
                                    url: addToCartUrl,
                                    method: 'POST',
                                    data: form,
                                    success: function (data) {
                                        // hide the quantity badge based on number of items in cart
                                        var cartQuantity = document.querySelector('.minicart-quantity');
                                        data.quantity === 0 ? cartQuantity.classList.add('d-none') : cartQuantity.classList.remove('d-none');
                
                                        scope.methods.handlePostCartAdd(data);
                                        $('body').trigger('product:afterAddToCart', data);
                                        $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                                        $.spinner().stop();
                                        scope.methods.miniCartReportingUrl(data.reportingURL);
                                        //  Push addToCart event Data Here
                                        addToCartEvent(data.event_id, eventBtn);
                                    },
                                    error: function () {
                                        $.spinner().stop();
                                    }
                                });
                            }

                        } else {
                            $('.mutually-exclusive-products').removeClass('d-none');
                        }

                        resetRecipeAttributes();
                    },
                    error: function () {
                        $.spinner().stop();
                        resetRecipeAttributes();
                    }
                });
            } else {
                $(this).trigger('updateAddToCartFormData', form);
                if (addToCartUrl) {
                    // check if this is a paired value
                    if ($('.paired-buttons-container').length > 0) {
                        var pairedID = $('.paired-buttons-result-list button.selected').data('pid');
                        if (pairedID == undefined) {
                            pairedID = null;
                        }
                        form.pairedAction = true;
                        form.pairedParent = pid;
                        form.pairedChild = pairedID;
                    }
                    form.currentUrl = $(location).attr('href');
    
                    $.ajax({
                        url: addToCartUrl,
                        method: 'POST',
                        data: form,
                        success: function (data) {
                            // hide the quantity badge based on number of items in cart
                            var cartQuantity = document.querySelector('.minicart-quantity');
                            data.quantity === 0 ? cartQuantity.classList.add('d-none') : cartQuantity.classList.remove('d-none');
    
                            scope.methods.handlePostCartAdd(data);
                            $('body').trigger('product:afterAddToCart', data);
                            $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                            $.spinner().stop();
                            scope.methods.miniCartReportingUrl(data.reportingURL);
                            //  Push addToCart event Data Here
                            addToCartEvent(data.event_id, eventBtn)
                            resetRecipeAttributes();
                            //  Push addToCart event Data Here
                            if ($('*[data-gtmcartuniqueid]').length && data.event_id) {
                                addToCartEvent(data.event_id)
                            }
                        },
                        error: function () {
                            $.spinner().stop();
                            resetRecipeAttributes();
                        }
                    });
                }
            }
        });
    },
    //Attributes that display as color swatches
    colorAttribute: function () {
        var scope = this;

        $(document).on('click', '[data-attr="Color"] button, [data-attr="customColor"] button', function (e) {
            e.preventDefault();
            //PAP-2780: Searchable if Unavailable PDP issue: update selected product details and change button text to Sold Out
            if (checkForClickableAttribute($(this))) {
                return;
            }
            $(this).parent('.swatches').find('button, button input.swatch').removeClass('selected');
            $(this).addClass('selected');
            $(this).find('input.swatch').addClass('selected');
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            scope.methods.attributeSelect($(this).attr('data-url'), $productContainer);
            $productContainer.find('.color-display-value').text($(this).find('.swatch').data('displayvalue'));
        });

        $(document).on('click', '[data-attr="platformcolor"] button', function (e) {
            e.preventDefault();

            if($(this).attr('data-displayvalue') != 'No Platform') {
                $('.restoreColor').removeClass('restoreColor');
                $(this).addClass('restoreColor');
            }
            if ($(event.target).hasClass('platformcolor-value')) {
	            var colorValue = document.querySelectorAll('.color-display-value');
	            var platformColorTextValue = colorValue[1];
	            if(platformColorTextValue) {
	                platformColorTextValue.innerHTML = '';
	            	platformColorTextValue.innerHTML = event.target.firstElementChild.innerText;
	            }
            }
        });
        //PAP-2924 start: Add MagSafe Grips as 3rd radio selection on POD PDPs
        $(document).on('click', '[data-attr="magsafecolor"] button', function (e) {
            e.preventDefault();
            if ($(e.target).hasClass('magsafecolor-value')) {
                //update color text on selection
                $('.magsafecolor .color-display-value').html(e.target.firstElementChild.innerText);
            }
        });
        //PAP-2924 ends
    },
    //Attributes that display as non-color swatches
    nonColorAttribute: function () {
        var scope = this;

        $(document).on('click', 'button.swatch', function (e) {
            e.preventDefault(); 

            if (scope.methods.checkForClickableAttribute($(this)) || $(this).hasClass('mm-swatch')) {
                return;
            }
            // PAP-3089: removing selected class from other swatches
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            if ($productContainer.hasClass('mix-and-match-container')) {
                $.spinner().start();
            }

            scope.methods.attributeSelect($(this).attr('data-url'), $productContainer);

            $productContainer.find('.non-color-display-value').text($(this).find('.swatch-value').data('display-value'));
            if($(this).closest('.swatches').length) {
                var swatchesElems = $(this).closest('.swatches').find('button.swatch, button.swatch input.swatch');
                $(swatchesElems).removeClass('selected');
                $(this).addClass('selected');
                $(this).find('input.swatch').addClass('selected');
            }
            if($(this).closest('.swatches-holder').length || $(this).closest('.non-color-attribute-swatches')) {
                var swatchesElems = $(this).closest('.swatches-holder, .non-color-attribute-swatches').find('button.swatch, button.swatch span.swatch-value')
                $(swatchesElems).removeClass('selected');
                $(this).addClass('selected');
                $(this).find('span.swatch-value').addClass('selected');
            }
        });
    },
    //Attributes for mix and match base swatches
    mixAndMatchAttribute: function () {
        var scope = this;

        $(document).on('click', 'button.magsafe-baseColor-attribute, button.adhesive-baseColor-attribute', function (e) {
            e.preventDefault();
            if (scope.methods.checkForClickableAttribute($(this))) {
                return;
            }
            $.spinner().start();
            // PAP-3089: removing selected class from other swatches
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            var $swatchContainer = $(this).closest('.attribute-option.color-swatches');
            var $cardWrapper = $(this).closest('.card-wrapper.mix-and-match-wrapper');

            scope.methods.mmAttributeSelect($(this).attr('data-url'), $productContainer, $swatchContainer, $cardWrapper);
	        $cardWrapper.find('.style-name').text(e.target.firstElementChild.innerText);
        });

        $(document).on('click', '.card-wrapper.mix-and-match-wrapper .card-head', function (e) {
            e.preventDefault();
            $.spinner().start();
            var $mmcardwrapper = $(this).closest('.card-wrapper.mix-and-match-wrapper');
            var $productDetailContainer = $(this).closest('.product-detail');
            $productDetailContainer.find('.prices .price').html($mmcardwrapper.find('.mm-prices .price').html());
            var $mmcardwrappertype = $mmcardwrapper.data('attrvalue');
            if ($mmcardwrappertype != 'mm-topOnly') {
                if ($(this).attr('aria-expanded') == 'true') {
                    $mmcardwrapper.find('.collapse-area').addClass('show');
                    var $colorSwatches = $mmcardwrapper.find('.attribute-option.color-swatches');
                    $colorSwatches.find('button.mm-swatch').each(function(){
                        $(this).removeClass('selected');
                        $(this).find("span.Color-value.swatch-value").removeClass('selected');
                    });
                    $colorSwatches.find('button.mm-swatch').first().trigger('click');
                } else {
                    $.spinner().stop();
                }
            } else {
                if ($(this).attr('aria-expanded') == 'true') {
                    var $productContainer = $(this).closest('.set-item');
                    if (!$productContainer.length) {
                        $productContainer = $(this).closest('.product-detail');
                    }
                    scope.methods.mmAttributeSelect($mmcardwrapper.data('url'), $productContainer, null);
                } else {
                    $.spinner().stop();
                }
            }
            if ($mmcardwrappertype == 'mm-MagsafeBaseWithTop') {
                $mmcardwrapper.addClass('selected');
            } else {
                $mmcardwrapper.removeClass('selected');
            }
        });
    },
    //Attributes that display in a select dropdown (default)
    selectAttribute: function () {
        var scope = this;
        var variantRadioButton = document.querySelectorAll('.custom-control.attribute-option.custom-attribute-radio');
        var variantSwatch = document.querySelectorAll('.accordioncolor-value.Color-value.swatch-value.swatch-circle');
        var swatch = document.querySelector('.accordioncolor-value.Color-value.swatch-value.swatch-circle');
        var phoneSwatch = document.querySelectorAll('.swatch.Color-value.swatch-circle');
        var notifyMeContent = document.querySelector('#notify-me-section');
        var addToCartButtonGlobal = document.querySelector('.add-to-cart-global');
        var addToCartButton = document.querySelector('.add-to-cart');
        var colorValue = document.querySelectorAll('.color-display-value');
        var dropdownPicker = document.querySelector('.custom-select.form-control');
        var variantProduct = '.custom-control.attribute-option.custom-attribute-radio';

        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            scope.methods.attributeSelect(e.currentTarget.value, $productContainer);
        });

        $(document).on('change', '.custom-attribute-radio input[type=radio]', function (e, autoUpdateCall) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            scope.methods.attributeSelect(e.currentTarget.value, $productContainer, autoUpdateCall);
        });

        $(phoneSwatch).on('click touch', function(event) {
            var targetSpan = event.target;
            var accordianColorTextValue = colorValue[0];
            var phoneSwatchCurrentElems = $(this).closest('.swatches').find('.swatch.Color-value.swatch-circle');
            if(!notifyMeContent) return;
            if (targetSpan.classList.contains('out-of-stock')) {
                for (var i = 0; i < phoneSwatchCurrentElems.length; i++) {
                    phoneSwatchCurrentElems[i].classList.remove('selected');
                }
                accordianColorTextValue.innerHTML = '';
                accordianColorTextValue.innerHTML = event.target.firstElementChild ? event.target.firstElementChild.innerText : '';
                if(addToCartButton && addToCartButton.length) {
                    addToCartButton.classList.add('disabled');
                }
                if($(document).find('.ootb-bundle.bundle-items').length == 0) {
                    notifyMeContent.classList.remove('d-none');
                }
                targetSpan.classList.add('selected');
            } else {
                if(addToCartButton && addToCartButton.length) {
                    addToCartButton.classList.remove('disabled');
                }
                notifyMeContent.classList.add('d-none');
            }
        });

        //PAP-2108: make out of stock swatches selectable
        $('.color-swatch-wrapper').on('click touch', function(event) {
            var swatches = $(this).closest('.swatches').find('.swatch.swatch-circle');
            var targetSpan = $(this).find('input');

            if(!notifyMeContent) return;
            if (targetSpan.hasClass('unselectable')) {
                for (var i = 0; i < swatches.length; i++) {
                    swatches[i].classList.remove('selected');
                }
                if(addToCartButton && addToCartButton.length) {
                    addToCartButton.classList.add('disabled');
                }
                if($(document).find('.ootb-bundle.bundle-items').length == 0) {
                    notifyMeContent.classList.remove('d-none');
                }
                targetSpan.addClass('selected');
                $('.color-swatches .color-display-value').text(targetSpan.attr('data-displayvalue'))
            } else {
                if(addToCartButton && addToCartButton.length) {
                    addToCartButton.classList.remove('disabled');
                }
                notifyMeContent.classList.add('d-none');
            }

            // PAP-1522: Master SKU setup not working with customStyle variation attribute
            var attributeId = $(this).data('attr-id');
            if(attributeId) {
                $('input[name="customStyle"]').each(function(elem){
                    var attrUrl = $(this).val();
                    if(attrUrl){
                        attrUrl = new URL(attrUrl);
                        var attrUrlParams = new URLSearchParams(attrUrl.search);
                        attrUrlParams.forEach(function(value, key) {
                            if(key.indexOf('customColor') > -1) {
                                attrUrlParams.set(key, attributeId);
                            }
                        });  
                        var new_attrUrl = new URL(`${attrUrl.origin}${attrUrl.pathname}?${attrUrlParams.toString()}`);
                        $(this).val(new_attrUrl);
                    }
                });
            }
        });
        //PAP-2108 end

        $(variantSwatch).on('click touch', function(event) {
            var targetSpan = event.target;
            var accordianColorTextValue = colorValue[0];

            if(!notifyMeContent) return;

            if (accordianColorTextValue) {
            	accordianColorTextValue.innerHTML = '';
            	accordianColorTextValue.innerHTML = event.target.firstElementChild.innerText;
            }            
            if (targetSpan.classList.contains('out-of-stock')) {
                for (var i = 0; i < variantSwatch.length; i++) {
                    variantSwatch[i].classList.remove('selected');
                }
                addToCartButtonGlobal.classList.add('disabled');
                if($(document).find('.ootb-bundle.bundle-items').length == 0) {
                    notifyMeContent.classList.remove('d-none');
                }
                targetSpan.classList.add('selected');
            } else {
                addToCartButtonGlobal.classList.remove('disabled');
                notifyMeContent.classList.add('d-none');
            }
        });

        $('button.mm-style-swatch').on('click touch', function(e) {
            $(this).closest('.color-swatches').find('span.style-name').html(e.target.firstElementChild.innerText);
        });

        $(swatch).on('click touch', function(event) {
            var targetSpan = event.target;
            var accordianColorTextValue = colorValue[0];

            if (targetSpan.classList.contains('out-of-stock')) {
                for (var i = 0; i < swatch.length; i++) {
                    swatch[i].classList.remove('selected');
                }
                accordianColorTextValue.innerHTML = '';
                accordianColorTextValue.innerHTML = event.target.firstElementChild.innerText;
                if($(document).find('.ootb-bundle.bundle-items').length == 0) {
                    notifyMeContent.classList.remove('d-none');
                }
                addToCartButton.classList.add('disabled');
            } else {
                addToCartButton.classList.remove('disabled');
                notifyMeContent.classList.add('d-none');
            }
        });



        $(dropdownPicker).on("change", function() {
            var selectedOption = $(this).find('option:selected');
            var itemClassList = selectedOption[0].classList.value;

            if (!notifyMeContent) return;
            if (itemClassList == 'out-of-stock') {
                if($(document).find('.ootb-bundle.bundle-items').length == 0) {
                    notifyMeContent.classList.remove('d-none');
                }
                addToCartButton.classList.add('disabled');
            } else {
                notifyMeContent.classList.add('d-none');
                addToCartButton.classList.remove('disabled');
            }
        });

        $(variantRadioButton).on('click touch', function() {
            var topAndBase = 'gripSetup-1';
            $('.bundle-swatches').removeClass(topAndBase);
            if(this.id == topAndBase) {
                $('.bundle-swatches').addClass(this.id);
            }
            if(!notifyMeContent) return;
            if (this.classList.contains('out-of-stock')) {
                if($(document).find('.ootb-bundle.bundle-items').length == 0) {
                    notifyMeContent.classList.remove('d-none');
                }
                addToCartButton.classList.add('disabled');
            } else {
                notifyMeContent.classList.add('d-none');
                addToCartButton.classList.remove('disabled');
            }
        });
        //PAP-3510 - update image class if magsafebase pre selected on page load
        if ($('.product-detail.bundle-item.custom-bundle').length && $('input#MagSafeBase').is(':checked')) {
            $('.bundle-items .primary-images').addClass('magsafe-grips-slider-images');
        }

        $('body').on('click', variantProduct, function(e) {
            if($('.bundle-swatches').is('*')) {
                e.preventDefault();
                var platform =  $('.bundle-swatch-platformcolor');
                var accordian =  $('.bundle-swatch-accordioncolor');
                var magsafe =  $('.bundle-swatch-magsafecolor');
                $('.bundle-swatches').removeClass('gripSetup-1 gripSetup-2').addClass(this.id);
                //PAP-3510 - update conditions based on attribute values
                if($(this).data('attrvalue') == 'topOnly') {
                    // PAP-3089: fix for custom bundle products
                    var topOnlyUrl = $('.platformcolor-attribute[data-displayvalue="No Platform"]').data('toponly-url');
                    var urlParams = new URL(topOnlyUrl);
                    var productId = urlParams.searchParams.get('pid');
                    urlParams.searchParams.set('dwvar_'+ productId +'_accordioncolor', $('.accordioncolor-attribute:not(.visually-hidden)').first().data('value'));
                    urlParams.searchParams.set('dwvar_'+ productId +'_gripSetup', 'topOnly');
                    urlParams.searchParams.set('dwvar_'+ productId +'_platformcolor', 'None');
                    //PAP-3700 - update magsafe value in url only if it contatins magsafe parameter
                    if (topOnlyUrl.includes('_magsafecolor')) {
                        urlParams.searchParams.set('dwvar_'+ productId +'_magsafecolor', 'NoMagsafeGrip');
                    }
                    //PAP-3700 end
                    topOnlyUrl = urlParams.toString();
                    $('.platformcolor-attribute[data-displayvalue="No Platform"]').attr('data-url', topOnlyUrl);
                    $('.platformcolor-attribute[data-displayvalue="No Platform"]').removeClass('selected').trigger('click');
                    platform.slideUp();
                    magsafe.slideUp();
                    accordian.slideDown();
                    setTimeout(function(){
                        $('input#MagSafeBase').prop('checked', false);
                        $('input#gripOnly').prop('checked', false);
                        $('input#topOnly').prop('checked', true);
                    }, 100);
                //PAP-2924 start: Add MagSafe Grips as 3rd radio selection on POD PDPs
                } else if ($(this).data('attrvalue') == 'MagSafeBase') {
                    var magsafevalue = $('.magsafecolor-attribute').first().data('value');
                    //PAP-4518 - update value if hidden swatch selected
                    if (magsafevalue == 'NoMagsafeGrip') {
                        magsafevalue = $('.magsafecolor-attribute').eq(1).data('value');
                    }
                    var magsafeyUrl = $('.magsafecolor-attribute[data-value="NoMagsafeGrip"]').attr('data-magsafe-url');
                    //update url for magsafe selection
                    var urlParams = new URL(magsafeyUrl);
                    var productId = urlParams.searchParams.get('pid');
                    urlParams.searchParams.set('dwvar_'+ productId +'_accordioncolor', 'None');
                    urlParams.searchParams.set('dwvar_'+ productId +'_gripSetup', 'MagSafeBase');
                    urlParams.searchParams.set('dwvar_'+ productId +'_platformcolor', 'None');
                    urlParams.searchParams.set('dwvar_'+ productId +'_magsafecolor', magsafevalue);
                    magsafeyUrl = urlParams.toString();
                    $('.magsafecolor-attribute[data-value="NoMagsafeGrip"]').attr('data-url', magsafeyUrl);
                    $('.magsafecolor-attribute[data-value="NoMagsafeGrip"]').removeClass('selected').trigger('click');
                    platform.slideUp();
                    accordian.slideUp();
                    magsafe.slideDown();
                    setTimeout(function(){
                        $('input#MagSafeBase').prop('checked', true);
                        $('input#gripOnly').prop('checked', false);
                        $('input#topOnly').prop('checked', false);
                    }, 100);
                }
                //PAP-2924 end
                else {
                    // PAP-3089: fix for custom bundle products
                    var restoreColor = $('.restoreColor');
                    var restoreElement = restoreColor.first();
                    var restoreBaseColorElement = restoreColor.last();
                    var restoreElementUrl = restoreElement.data('url');
                    var baseRestoreColor = restoreBaseColorElement.data('value');
                    restoreElementUrl = restoreElementUrl.replace('topOnly', 'gripOnly');
                    //PAP-3473 start: Fixed toggle between radio buttons not working correctly
                    if (baseRestoreColor == 'NoMagsafeGrip') {
                        //update base color value if it contains NoMagsafeGrip
                        baseRestoreColor = $('.platformcolor-attribute.restoreColor').data('value');
                    }
                    restoreElementUrl = restoreElementUrl.replace('None', baseRestoreColor);
                    var urlParams = new URL(restoreElementUrl);
                    var productId = urlParams.searchParams.get('pid');
                    urlParams.searchParams.set('dwvar_'+ productId +'_gripSetup', 'gripOnly');
                    //PAP-3700 start- update magsafe value in url only if it contatins magsafe parameter
                    if (restoreElementUrl.includes('_magsafecolor')) {
                        urlParams.searchParams.set('dwvar_'+ productId +'_magsafecolor', 'NoMagsafeGrip');
                    }
                    //PAP-3700 end
                    if (urlParams.searchParams.get('dwvar_'+ productId +'_platformcolor') == 'None') {
                        urlParams.searchParams.set('dwvar_'+ productId +'_platformcolor', $('.platformcolor-attribute:not(.visually-hidden)').first().data('value'));
                    };
                    if (urlParams.searchParams.get('dwvar_'+ productId +'_accordioncolor') == 'None') {
                        urlParams.searchParams.set('dwvar_'+ productId +'_accordioncolor', $('.accordioncolor-attribute:not(.visually-hidden)').first().data('value'));
                    };
                    restoreElementUrl = urlParams.toString();
                    //PAP-3473 end
                    restoreElement.attr('data-url', restoreElementUrl);
                    restoreElement.removeClass('selected').trigger('click');
                    platform.slideDown();
                    accordian.slideDown();
                    magsafe.slideUp();
                    setTimeout(function(){
                        $('input#MagSafeBase').prop('checked', false);
                        $('input#gripOnly').prop('checked', true);
                        $('input#topOnly').prop('checked', false);
                    }, 100);
                }
                //PAP-2924 start: Add MagSafe Grips as 3rd radio selection on POD PDPs
                $('body').on('product:afterAttributeSelect', function (e, response) {
                    //Fixed selected colors text not updating on radio button selection
                    $(response.container).find('.bundle-swatches div.swatch:visible').each(function() {
                        var selectedattr = $(this).find('.color-swatches .swatch.selected').attr('data-displayvalue');
                        if (selectedattr) {
                            $(this).find('.color-swatches .color-display-value').empty().html(selectedattr);
                        }
                    });
                });
                //PAP-2924 end
            }
        });

        $('body').on('product:afterAttributeSelect', function (e, response) {
                var $recommendationTargets = $(document).find('.einstein-data');
                var sku = response.data.product.id;
                var id;
                $recommendationTargets.each(function () {
                    var $recTrigger = $(this);
                    var data = $recTrigger.data() || {};
                    if (data.anchors && data.anchors.length > 0 && data.anchors[0].sku) {
                        data.anchors[0].sku = sku;
                        einsteinUtils.viewProductActivity({id: data.anchors[0].id, sku: data.anchors[0].sku});
                    }
                });
            $(document).triggerHandler('load.einstein');
        });
    },
    addOnToCart: function () {
        var scope = this;
        $(document).on('click', '.js-product-addon', function (e) {
            e.preventDefault();
            var btn = $(this);
            var addToCartUrl,pid,pidsObj;
            $('body').trigger('product:beforeAddToCart', this);
            pid = $(this).closest('.product-tile').data('pid');
            var $productContainer = $(this).closest('.product-tile');
            addToCartUrl = $(this).attr('href');
            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: scope.methods.getChildProducts(),
                quantity: 1,
                options: [],
                currentUrl: $(location).attr('href')
            };
            if (pid && pid.toString().includes('MSMM_')) {
                var pidArr = pid.split('_');
                var pairedID;
                if (pidArr.length > 2) {
                    pid = pidArr[1];
                    pairedID= pidArr[2];
                }
                form.pairedAction = true;
                form.pid = pid;
                form.pairedParent = pid;
                form.pairedChild = pairedID;
            }
            form.currentUrl = $(location).attr('href');

            if($('.minicart').hasClass('flyout-enabled')) {
                $('.minicart.flyout-enabled [data-flyout-wrapper]').spinner().start();
            }
            
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        if ($(btn).hasClass('js-quick-add-to-cart')) {
                            $(btn).find('.quick-add-to-cart-text').attr('data-previous-text', $(btn).find('.quick-add-to-cart-text').text());
                            $(btn).find('.quick-add-to-cart-text').text($(btn).find('.quick-add-to-cart-text').attr('data-text'));
                            $(btn).find('.icon-cart').addClass('d-none');
                            $(btn).find('.icon-checkmark').removeClass('d-none');
                            setTimeout(function () {
                                $(btn).find('.quick-add-to-cart-text').text($(btn).find('.quick-add-to-cart-text').attr('data-previous-text'));
                                $(btn).find('.icon-cart').removeClass('d-none');
                                $(btn).find('.icon-checkmark').addClass('d-none');
                            }, 1000);
                        }
                        scope.methods.handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $('body').trigger('product:afterAddToCartQuickview', data);
                        $.spinner().stop();
                        scope.methods.miniCartReportingUrl(data.reportingURL);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            if($('.js-bundle').length > 0) {
                var $productContainer = $('.js-bundle');
            } else {
                var $productContainer = response.$productContainer;
            }
            //PAP-2780: Searchable if Unavailable PDP issue: update selected product details and change button text to Sold Out
            if ((!response.product.readyToOrder || !response.product.available) && response.product.availability && response.product.availability.status) {
                $productContainer.find('button.add-to-cart').html(response.product.availability.status);
                // PAP-3089: Updating content for global add to cart button
                if($($productContainer).find('button.add-to-cart-global').length > 0) {
                    $($productContainer).find('button.add-to-cart-global .action-msg').html(response.product.availability.status);
                    $($productContainer).find('button.add-to-cart-global').find('.price').hide();
                }
            } else {
                $productContainer.find('button.add-to-cart').html(response.product.availability.status);
                // PAP-3089: Updating content for global add to cart button
                if($($productContainer).find('button.add-to-cart-global').length > 0) {
                    $($productContainer).find('button.add-to-cart-global .action-msg').html(response.product.availability.status);                    
                    $($productContainer).find('button.add-to-cart-global').find('.price').show();
                }
            }
            // update local add to cart (for sets)
            $productContainer.find('button.add-to-cart').attr('disabled',
                (!response.product.readyToOrder || !response.product.available));
            // update global add to cart (single products, bundles)
            var $dialog = $(response.$productContainer).closest('.quick-view-dialog');
            if ($dialog.length){
                $dialog.find('.add-to-cart-global').attr('disabled',
                    !$dialog.find('.global-availability').data('ready-to-order')
                    || !$dialog.find('.global-availability').data('available')
                );
            } else {
                updateCartButton();
            }
        }); 
    },
    checkByoBundle: function () {
        if ($('.product-bundle').hasClass('byo-bundle')) {
            $('body').addClass('byo-pdp');
        }
    },
    poptopDesign: function () {
        $(document).on('click', '.pop-top-design button', function (e) {
            e.preventDefault();
            var $productContainer = $('.byo-bundle');
            $productContainer.find('.pop-design-color-display-value').text($(this).find('.swatch').data('displayvalue'));
            $productContainer.find('.pop-top-design button').removeClass('selected');
            $productContainer.find('.pop-top-design input').removeClass('selected').removeAttr('selected');
            $(this).addClass('selected');
            $(this).find('input').addClass('selected').attr('selected', 'selected');
            var src = $(this).find('input').attr('overlayimage');
            $productContainer.find('img.overlay-image').attr('src', src);
            $('#popTopDesignSelection').empty().html($(this).find('input').data('attr-value'));
            $('.ootb-bundle.js-bundle-images.selected').removeClass('selected');
            $('#popTopDesignSelection').closest('.ootb-bundle.js-bundle-images').addClass('selected');
        });
    },
    detailsPopover: function () {
        $(document).on('click', '.details-popover .btn-detail', function (e) {
            e.preventDefault();
            $(this).closest("div").find('.popover').show();
        });
        
        $(document).on('click', '.details-popover .popover-close', function (e) {
            e.preventDefault();
            $(this).parent('.popover').hide();
        });

        $('.details-popover .btn-detail').on('click',function () {
            var popoverId = $(this).parent('.details-popover').attr('data-pid');
            var popoverPosition = $('.product-detail[data-pid='+popoverId+']').offset();
            var popoverHeight = $(".popover").height()
            if (popoverId) {
                $('html,body').animate({ scrollTop: popoverPosition.top - popoverHeight });
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid .remove-bonus-product', event => {
            $(event.target).closest('.selected-pid').remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }
            if (count == 0) {
                $('.add-bonus-products').addClass('disabled')
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            $('body').trigger('modal:loaded', $('#chooseBonusProductModal')); // update quickview modal scroll height
        });
    },
    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var masterpid = $(this).data('masterpid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if ($('.add-bonus-products').hasClass('disabled')) {
                $('.add-bonus-products').removeClass('disabled');
            }
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ''
                + '<div class="selected-pid" '
                + 'data-pid="' + pid + '"'
                + 'data-masterpid="' + masterpid + '"'
                + 'data-qty="' + submittedQty + '"'
                + 'data-optionID="' + (optionID || '') + '"'
                + 'data-option-selected-value="' + (valueId || '') + '"'
                + '>'
                + '<div class="bonus-product-name">'
                + $choiceOfBonusProduct.find('.product-name').html()
                + '</div>'
                + '<div class="remove-bonus-product"></div>'
                + '</div>'
                ;
                $('#chooseBonusProductModal .selected-bonus-products .bonus-summary-products-container').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
                $('body').trigger('modal:loaded', $('#chooseBonusProductModal')); // update quickview modal scroll height
            } else {
                var selectedBonusProductHtml = ''
                + '<div class="selected-pid" '
                + 'data-pid="' + pid + '"'
                + 'data-masterpid="' + masterpid + '"'
                + 'data-qty="' + submittedQty + '"'
                + 'data-optionID="' + (optionID || '') + '"'
                + 'data-option-selected-value="' + (valueId || '') + '"'
                + '>'
                + '<div class="bonus-product-name">'
                + $choiceOfBonusProduct.find('.product-name').html()
                + '</div>'
                + '<div class="remove-bonus-product"></div>'
                + '</div>'
                ;
                $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                    if ($(this).data('masterpid') === masterpid && $(this).data('pid') !== pid) {
                        $(this).find('.remove-bonus-product').click();
                        $('#chooseBonusProductModal .selected-bonus-products .bonus-summary-products-container').append(selectedBonusProductHtml);
                        $('.pre-cart-products').html(maxPids);
                        $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
                        $('body').trigger('modal:loaded', $('#chooseBonusProductModal')); // update quickview modal scroll height
                        return false;
                    } else {
                        $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
                    }
                });
            }
        });
    }
});

window.addEventListener('load', function() {
    if($('.product-customizer-pdp').length > 0) {
        return;
    }

    if($('.product-detail.product-wrapper').data('product-type') == 'bundle' && $('.accordioncolor-attributes').data('default') && $('.platformcolor-attributes').data('default')) {
        var defaultAccordion = $('.accordioncolor-attributes').data('default');
        var defaultPlatform = $('.platformcolor-attributes').data('default');
        var gripSetup = $('input[name="gripSetup"]:checked').length ? $('input[name="gripSetup"]:checked') : $('input[name="gripSetup"]:first');
        var url = gripSetup.val();
        var selectedAccordion = $('.select-accordioncolor .selected').data('attr-value');
        var selectedPlatform = $('.select-platformcolor .selected').data('attr-value');

        var findAccordion = "accordioncolor=" + selectedAccordion;
        var findPlatform = "platformcolor=" + selectedPlatform;

        var replaceAccordion = "accordioncolor=" + defaultAccordion;
        var replacePlatform = "platformcolor=" + defaultPlatform;
        
        if(url.indexOf(findAccordion) !== -1) {
            url = url.replace(findAccordion, replaceAccordion);
            $('.accordioncolor .color-display-value').empty().html($('.accordioncolor-attribute span[data-attr-value="'+defaultAccordion+'"').data('display-value'));
        }
        
        if(url.indexOf(findPlatform) !== -1) {
            url = url.replace(findPlatform, replacePlatform);
            $('.platformcolor .color-display-value').empty().html($('.platformcolor-attribute span[data-attr-value="'+defaultPlatform+'"').data('display-value'));
        }

        gripSetup.val(url);
        gripSetup.trigger('change', true);
    }

    if ($('.pop-top-design').length && $('.byo-bundle').length) {
        var $productContainer = $('.byo-bundle');
        var avaliableAttributeValues = $productContainer.find('div[data-availabe-attribute-values]').data('availabe-attribute-values');
        var isSelected = true;
        if (avaliableAttributeValues) {
            $productContainer.find('.pop-top-design button').each( function(item) {
                $(this).removeClass('selected');
                $(this).find('input').removeClass('selected').removeAttr('selected');

                if (avaliableAttributeValues.indexOf($(this).attr('aria-describedby')) > -1) {
                    $(this).removeClass('d-none');
                    if (isSelected) {
                        $productContainer.find('.pop-design-color-display-value').text($(this).find('.swatch').data('displayvalue'));
                        $(this).addClass('selected');
                        $(this).find('input').addClass('selected').attr('selected', 'selected');
                        var src = $(this).find('input').attr('overlayimage');
                        $productContainer.find('img.overlay-image').attr('src', src);
                        $('#popTopDesignSelection').empty().html($(this).find('input').data('attr-value'));
                        isSelected = false;
                    }
                } else {
                    $(this).addClass('d-none');
                }                
            });
        }
        
    }
});

module.exports = exportDetails;
'use strict';

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
 var focusHelper = require('base/components/focus');
 var modal = require('../components/modal');
 const { updateAddAllToCart } = require('./detail');

function fillModalElement(selectedValueUrl) {
    var $modal = $('#quickViewModal');
    $modal.find('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            var parsedHtml = modal.parseHtml(data.renderedTemplate);

            $modal.find('.modal-body').empty();
            $modal.find('.modal-body').html(parsedHtml.body);
            $modal.find('.modal-footer').html(parsedHtml.footer);
            $modal.find('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $modal.find('.full-pdp-link').attr('href', data.productUrl);
            $modal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            $modal.find('.modal-header .quickviewHeading').text(data.headingText);
            $modal.find('.enter-message').text(data.enterDialogMessage);
            $modal.find('#quickViewModal').modal('show');

            if (data.dialogTitle) {
                $modal.find('.modal-header').prepend('<h2 class="modal-title">' + data.dialogTitle + '</h2>') //PAP-1119 changed div to h2
            }

            if (data.product.productType === 'set') {
                updateAddAllToCart();
            }

            $('body').trigger('modal:loaded', $('#quickViewModal')); // sending custom event for scroll body sizing
            $('body').trigger('quickview:ready', $('#quickViewModal')); // sending custom event for slider init
            $('body').trigger('tooltip:init');

            $.spinner().stop();
        },
        error: function() {
            $.spinner().stop();
        }
    });
}

module.exports = {
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).closest('a.quickview').attr('href');
            $(e.target).trigger('quickview:show');
            modal.getModalHtmlElement('quickViewModal', 'quick-view-dialog');
            fillModalElement(selectedValueUrl);
        });
    },
    focusQuickview: function() {
        $('body').on('shown.bs.modal', '#quickViewModal', function() {
            $('#quickViewModal').siblings().attr('aria-hidden', 'false');
            $('#quickViewModal .close').focus();
        });
    },
    trapQuickviewFocus: function() {
        $('body').on('keydown', '#quickViewModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModal',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.qv-product-edit-add',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    showSpinner: function() {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },
    hideDialog: function() {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
            $('#quickViewModal').siblings().attr('aria-hidden', 'true');
        });
    },
    beforeUpdateAttribute: function() {
        $('body').on('product:beforeAttributeSelect', function () {
            $('.modal.show .modal-content').spinner().start();
        });
    }
};

'use strict';

function redirectToCheckout() {
    var url = $('a.checkout-btn').attr('href');
    if ($('rally-checkout-button .checkout-btn').length > 0 && !$('rally-checkout-button').hasClass('rally-d-none') && !$('rally-checkout-button .checkout-btn').hasClass('disabled')) {
        $('rally-checkout-button .checkout-btn').trigger('click');
    } else if (url.length > 0 && !$('a.checkout-btn').hasClass('disabled')) {
        window.location = url;
    }
}

module.exports = {

    init: function(){
        //Einstein has a delay on the logic so if it doesn't exist it won't fire. 
        setTimeout(function() {
            var crossSells = $('#cross-sell-data');
            if(!crossSells.is('*')) return;

            var crosssellID = crossSells.data('pid'),
                csInCart = $('.crossSellID').is('*') && $('.crossSellID').hasClass(crosssellID) ? true : false;

            if(csInCart) {
                crossSells.remove();
            } else {
                $('.checkout-btn').addClass('trigger-cross-sell');
                $('body').on('quickview:ready', function() {
                    var qv = $('#quickViewModal').find('.product-quickview.product-detail'),
                        pid = qv.data('pid');
                        if(crosssellID == pid) {
                            $('.checkout-btn').removeClass('trigger-cross-sell');
                            if($('#cross-sell-data').data('crosssellcopy')) {
                                $('#cross-sell-target').html($('#cross-sell-data').data('crosssellcopy'));
                            }
                            crossSells.remove();
                            $('.cross-sell-decline').show();
                            qv.addClass('cross-sell');
                            $('.modal-backdrop').addClass ("quickview-backdrop");
                        }
                });

                $(document).on('hidden.bs.modal','#quickViewModal', function (e) {
                    //redirect to checkout when modal is closed
                    var crossSellModal = $(e.target).find('.modal-content .modal-body .product-detail.cross-sell');
                    if (crossSellModal.length > 0) {
                        redirectToCheckout();
                    }
                });

                $(document).on('click','#quickViewModal button.qv-product-edit-add',function (e) {
                    //redirect to checkout when add to bag is clicked
                    var crossSellModal = $(e.target).closest('.modal-content').find('.modal-body .product-detail.cross-sell');
                    if (crossSellModal.length > 0) {
                        redirectToCheckout();
                    }
                });

                $('body').on('click', '.quickview', function(e) {
                    e.preventDefault();
                    if(document.readyState != "complete") {
                        redirectToCheckout();
                    }
                });     
                $('body').on('click', '#quickViewModal .cross-sell-decline, #quickViewModal .close', function(e) {
                   e.preventDefault();
                   redirectToCheckout(); 
                });
            }
        }, 2000);
    }
};
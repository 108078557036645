'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var SiteConstants = require('constants/SiteConstants');
var hoverIntent = require('jquery-hoverintent');

function megaMenuTop() {
    if ($(window).width() < 1024) {
        var bannerHeight = $(".header-banner").height();
        $('.main-menu.menu-toggleable-left').css("top", bannerHeight);
    }
}

var clearSelection = element => {
    var $currentPane = $(element).closest('.custom-dropdown.show');

    $currentPane.removeClass('show');

    // Adjust DOM after drawer finishes closing (mobile) or dropdown finishes hiding (desktop)
    setTimeout(() => {
        $currentPane.find('.nav-link').attr('aria-expanded', 'false');
        $currentPane.find('.dropdown-menu').attr('aria-hidden', 'true');
        $currentPane.find('.top-category').detach();
        $currentPane.find('.nav-menu').detach();

        if (!$currentPane.closest('.custom-dropdown.show').length) {
            $currentPane.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
        }
    }, SiteConstants.TransitionSpeed);
};

$('.logo-home, .icon-favorite, .dropdown-toggle').on('mousedown mouseup click dblclick', function(event) {
    var target = $(event.target);
    
    if (event.type === 'mousedown' || event.type === 'mouseup' || event.type === 'click' || event.type === 'dblclick') {
        target.addClass('selected').removeClass('focus-visible');
        
        target.parent().find('.logo-home, .icon-favorite, .dropdown-toggle').not(target).removeClass('selected');
    }
});

$('.minicart-link').on('mousedown mouseup click dblclick', function(event) {
    if (event.type === 'mousedown' || event.type === 'mouseup' || event.type === 'click' || event.type === 'dblclick') {
        $('.minicart-link').addClass('selected').removeClass('focus-visible');

        $('.minicart-link').not(this).removeClass('selected');
    }
});

var toggleDrawer = status => {
    var $header = $('header');
    var $headerBanner = $header.find('.header-banner');
    var $headerNav = $header.find('.header-nav');
    var headerNavHeight = !$headerBanner.hasClass('d-none') ? $headerNav.outerHeight() + $headerBanner.outerHeight() : $headerNav.outerHeight();
    var $mainMenu = $header.find('.main-menu');
    var $navbar = $mainMenu.find('.navbar-nav');
    var $modalBackground = $('.modal-background');

    if (status === 'open') {
        $('html, body').scrollTop($headerNav.offset().top);
        $('html, body').addClass('lock-scroll');
        $('body').addClass('mobile-menu-in');
        $mainMenu
            .addClass('in')
            .attr('aria-hidden', 'false')
            //PAP-647 .css('top', headerNavHeight)
            .siblings().attr('aria-hidden', 'true');
        $modalBackground
            .fadeIn(SiteConstants.TransitionSpeed)
            //PAP-647 .css('top', headerNavHeight);
        $header
            .siblings().attr('aria-hidden', 'true');
        $navbar
            .find('.nav-link').first().focus();

    } else {
        $('body').removeClass('mobile-menu-in');
        $('body').removeClass('modal-open');
        $navbar
            .find('.nav-menu, .top-category').detach();
        $navbar
            .find('.thirdCatOpen').removeClass('thirdCatOpen');
        $navbar
            .find('.show').removeClass('show');
        $mainMenu
            .removeClass('in')
            .attr('aria-hidden', 'true')
            .siblings().attr('aria-hidden', 'false');
        $header
            .siblings().attr('aria-hidden', 'false');
        $modalBackground
            .fadeOut(SiteConstants.TransitionSpeed);

        setTimeout(() => {
            $modalBackground.css('top', '');
            $('html, body').removeClass('lock-scroll');
            $headerNav.addClass('transparent-nav');
        }, SiteConstants.TransitionSpeed);
    }
};

$(document).ready(function() {
    function initializeResponsiveScripts() { 
        var useNewTopNavLayout = $('.usenewtopnavlayout').val();
        if (useNewTopNavLayout === 'true') { 
            if (window.matchMedia('(max-width: 1023px)').matches) {
                let touchStartY = 0;
                let isTouchMove = false;

                function toggleSubcategories($div) {
                    const $subcategoriesList = $div.siblings('.subcategories-list');
                    const isVisible = $subcategoriesList.css('display') === 'flex';

                    $('.mobile-subcategories').css('display', 'none');
                    $('.down-arrow').removeClass('top-arrow');

                    const $arrowToggle = $div.find('.down-arrow');
                    if (!isVisible) {
                        $subcategoriesList.css('display', 'flex');
                        $arrowToggle.addClass('top-arrow');
                    }
                }

                $(document).off('click', '.categories').on('click', '.categories', function(event) {
                    event.preventDefault();
                    toggleSubcategories($(this));
                });

                $(document).off('touchstart', '.categories').on('touchstart' , '.categories', function(event) {
                    if($(event.target).closest('.categories').length) {
                        touchStartY = event.touches[0].clientY;
                        isTouchMove = false;
                    }
                });

                $(document).off('touchmove').on('touchmove', function(event) {
                    isTouchMove = true;
                });

                $(document).off('touchend', '.categories').on('touchend', '.categories', function(event) {
                    if(!isTouchMove && $(event.target).closest('.categories').length) {
                        event.preventDefault();
                        toggleSubcategories($(event.target).closest('.categories'));
                    }
                });

                const $firstCategoryName = $('.font-for-nav');
                const $viewAllButton = $('.view-all-button');
                const $backButton = $('.close-menu .back');
                const $subcategoriesList = $('.subcategories-list');
                const $subcategoryimage = $('.subcategory-image');
                const $subcategoryname = $('.subcategory-name');
                const $navbar = $('.navbar-nav');
                const $footer = $('.mobile-nav-footer');

                $viewAllButton.off('click touchstart').on('click touchstart', function(event) {
                    event.preventDefault();
                    $subcategoriesList.addClass('grid-view');
                    $('.mobile-nav-wrap').addClass('grid-view');
                    $subcategoryimage.addClass('view-all-image-size');
                    $subcategoryname.addClass('view-all-font-size');
                    $firstCategoryName.addClass('space');
                    $('li.nav-item').hide();
                    $('div.swatches').hide();
                    var name = $(this).data('name');
                    $('div.swatches').each(function() {
                        if ($(this).hasClass(name)) {
                            $(this).show();
                        }
                    });
                    $subcategoriesList.each(function () {
                        if ($(this).hasClass(name)) {
                            $(this).show();
                        }
                    });
                    $viewAllButton.css('display', 'none');
                    $footer.hide();
                    $backButton.closest('.close-menu').css('display', 'block');
                });

                $backButton.off('click touchstart').on('click touchstart', function(event) {
                    event.preventDefault();
                    $subcategoriesList.removeClass('grid-view');
                    $('.mobile-nav-wrap').removeClass('grid-view');
                    $firstCategoryName.removeClass('space');
                    $subcategoryimage.removeClass('view-all-image-size');
                    $subcategoryname.removeClass('view-all-font-size');
                    $('li.nav-item').show();
                    $('div.swatches').show();
                    $backButton.closest('.close-menu').css('display', 'none');
                    $footer.show();
                    $viewAllButton.css('display', 'flex');
                });

                const $nav = $('.header-nav.usertopnavlayout');
                const $navbartoggler = $('.navbar-toggler');
                const $searchcontainer = $('.form-control.search-field');
                const $headercontainer = $('.header.containers');
                const $minicartquanity = $('.minicart-quantity');
                $navbartoggler.on('click', function(event) {
                    $('.mobile-nav-wrap').scrollTop(0);
                    $('.mobile-subcategories').css('display', 'none');
                    $('.down-arrow').removeClass('top-arrow');
                    if ($navbartoggler.hasClass('collapsed')) {
                        $searchcontainer.removeClass('background-blur border-search');
                        $headercontainer.removeClass('background-blur');
                        $minicartquanity.removeClass('background-opacity');
                    } else {
                        $searchcontainer.addClass('background-blur border-search');
                        $headercontainer.addClass('background-blur');
                        $minicartquanity.addClass('background-opacity');
                    }
                });
                $(document).on('click', function(event) {
                    if (!$('#top-header').is(event.target) && $('#top-header').has(event.target).length === 0 &&
                        !$navbartoggler.is(event.target) && $navbartoggler.has(event.target).length === 0) {
                        $searchcontainer.removeClass('background-blur  border-search');
                        $headercontainer.removeClass('background-blur');
                        $minicartquanity.removeClass('background-opacity');
                    }
                });

                $(document).on('click', function(event) {
                    if (!$(event.target).closest('.navbar-nav').length && 
                        !$(event.target).closest('.navbar-toggler').length) {
                        if ($('body').hasClass('mobile-menu-in')) {
                            toggleDrawer('close');
                            $searchcontainer.removeClass('background-blur  border-search');
                            $headercontainer.removeClass('background-blur');
                            $minicartquanity.removeClass('background-opacity');
                        }
                    }
                });
                $(document).on('click', function(event) {
                    if ($(event.target).closest('.main-menu.menu-toggleable-left').length === 0 && 
                        $(event.target).closest('.navbar-toggler').length === 0) {
                        toggleDrawer('close');
                    }
                });

                $(document).off('click', '.nav-item-cat:not(.custom-dropdown) .nav-link').on('click', '.nav-item-cat:not(.custom-dropdown) .nav-link', function(event) {
                    event.preventDefault();
                    const url = $(this).attr('href');
                    $footer.hide();
                    $('div.swatches').hide();
                    window.location.href = url;
                });
                $(window).on('load', function() {
                    $footer.show();
                    $('div.swatches').show();
                });
            }
        }
    }
    initializeResponsiveScripts();
    
    $(window).resize(function() {
        initializeResponsiveScripts();
    });
});

module.exports = () => {

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', () => {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link, .header-account-container .nav-link',
        {
            40: function(menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a').first().focus(); // set focus to the first menuitem
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function(menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a').first().focus();
                }
            },
            38: function(menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children().first().focus(); // set the focus to the last menuItem
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function(menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children().first().focus().attr('aria-expanded', 'false');
                }
            },
            27: function(menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    //if only search icon is visible, show the input on focus
    $( ".search-field" ).focus(function() {
        $(this).closest('.site-search').addClass('expanded');
    });

    // Custom dropdown behaviors for top menu
    var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
    $dropdownMenu.on('click', event => {
        event.stopPropagation(); // Prevent Bootstrap dropdown click events
        if (window.isMobile()) {
            // Drawer behavior on mobile
            event.preventDefault();
            var $dropdownLink = $(event.target);
            var $dropdown = $dropdownLink.closest('.custom-dropdown');
            var $li = $('<li class="dropdown-item top-category" role="button"></li>');
            var $closeMenu = $('<li class="nav-menu"></li>');
            var link = $dropdownLink.clone().removeClass('dropdown-toggle')
                .removeAttr('data-toggle')
                .removeAttr('aria-expanded')
                .attr('aria-haspopup', 'false');
            $li.append(link);
            $closeMenu.append($('.close-menu').first().clone());
            $dropdown.children('.dropdown-menu')
                .prepend($li)
                .prepend($closeMenu)
                .attr('aria-hidden', 'false')
                .find('a').removeAttr('tabindex', '-1');
            $dropdown.addClass('show');
            $dropdownLink.attr('aria-expanded', 'true');
            $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
            $(link).focus();
            $('.dropdown-menu').scrollTop(0);

            $('.menu-group .close-menu').on('click', function() {
                $('.navbar-nav').removeClass('lower-level-open');
            })
        } else {
            // Restore link behavior on desktop
            link = $(event.target).attr('href');
            if (link) {
                location.href = $(event.target).attr('href');
            }
        }
    });

    //Megamenu Mega-left menu
    $('.mega-left-menu-link').hoverIntent( function() {
            var menuName = this.id,
                menuRight = $('#' + menuName + '-right'),
                menuLeft = $(this).parent().parent(),
                menuRightParent = menuLeft.next();
                
            if (!window.isMobile()) {
                // clear classes
                menuLeft.find('.active').removeClass('active');
                menuRightParent.removeClass('has-sub-asset');
                menuRightParent.find('.active-list').removeClass('active-list');
                // add classes
                $(this).addClass('active');  
                menuRight.parent().addClass('active-list');
                // add a class to the parent Ul if a third level asset is displayed; css-hides the top level asset
                if (menuRight.parent().next().hasClass('sub-asset-1') || menuRight.parent().next().hasClass('sub-asset-2')) {
                    menuRightParent.addClass('has-sub-asset');
                }
            }
        },
        50
    );

    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $dropdownMenu.hoverIntent({
        over: event => {
            if (!window.isMobile()) {
                var eventElement = event.target;
                // Close all dropdowns
                $('.navbar-nav > li').each((index, element) => {
                    if (!$.contains(element, eventElement)) {
                        var $navItem = $(element);
                        $navItem.find('.show').each(() => {
                            clearSelection(element);
                        });
                        if ($navItem.hasClass('show')) {
                            $navItem.removeClass('show');
                            $navItem.children('ul.dropdown-menu').removeClass('show');
                            $navItem.children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // Open current dropdown
                $(eventElement).parent().addClass('show');
                $(eventElement).siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                $(eventElement).attr('aria-expanded', 'true');
            }
        },
        out: () => {
            // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
        },
        interval: 50
    });

    // Desktop - close menu on mouseleave
    $dropdownMenu.parent().on('mouseleave', event => {
        if (!window.isMobile()) {
            var $dropdown = $(event.currentTarget);
            // Close current dropdown
            $dropdown.removeClass('show');
            $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
            $dropdown.children('.nav-link').attr('aria-expanded', 'false');
        }
    });

    $('.navbar-nav').on('click', '.back', event => {
        event.preventDefault();
        clearSelection(event.target);
    });

    $('.navbar-toggler').click(event => {
        event.preventDefault();
        if ($('body').hasClass('mobile-menu-in')) {
            toggleDrawer('close');
            $('.header-nav').addClass('transparent-nav');
            $('.third-open').removeClass('third-open');
            $('.lower-level-open').removeClass('lower-level-open')
        } else {
            toggleDrawer('open');
            $('.header-nav').removeClass('transparent-nav');
        }
    });

    //Begin PAP-647
    $('#search-mobile').click(event => {
        event.preventDefault();
        $('.search-mobile .site-search').find('input[type="text"]').focus();
        $('.main-menu').addClass('in search');
    });

    $('.close-main-menu').click(event => {
        event.preventDefault();

        if ($('body').hasClass('mobile-menu-in')) {
            toggleDrawer('close');
        } else {
            toggleDrawer('open');
            $('.header-nav').removeClass('transparent-nav');
        }
    });
    //End PAP-647
    
    $('html').on('click', '.mobile-menu-in .modal-background', () => toggleDrawer('close'));

    // Prevent iOS from scrolling to bad position on search field input focus
    $('.search-mobile .search-field').focus(event => {
        if (window.isMobile()) {
            var currentScrollPosition = $('html').scrollTop();
            setTimeout(() => {
                $('html').scrollTop(currentScrollPosition);
                $('.modal-background').show();
            }, SiteConstants.TransitionSpeed);
        }
    });
    
    $('.nav-item-cat .nav-link').on('click', function() { 
        $('.navbar-nav').addClass('lower-level-open');
    });

    $('.custom-dropdown .nav-link').on('click', function() { 
        $('body').addClass('sub-category-open');
    });

    $('.user .nav-link').on('mouseenter focusin touchstart', () => {
        $('.user.custom-dropdown, .user .dropdown-menu').addClass('show');
        $('.fa-search, .reset-button').attr('tabindex', '-1');
    });

    document.addEventListener('keydown', function(event){
        if(event.key === "Escape"){
            $('.user.custom-dropdown, .user .dropdown-menu').removeClass('show');
        }
    });

    $(document).on('focusin', '.minicart-link', function(){
        $('.user .dropdown-menu').removeClass('show');
    });

    $('.nav-link').keydown(function(e) {
        if (e.keyCode == 27) return false;
    });

    $('html').on('click', '.main-menu .navbar .close-menu button', function() { 
        $('body').removeClass('sub-category-open');
    });
    
    $(document).on('focusout', '.suggestions li.more-below', function(){
        $('.fa-search, .reset-button').attr('tabindex', '0');
        $('.suggestions').hide();
        $('.fa-search').focus();
    });

    if($('ol.breadcrumb').children('li').length > 3) {
        $('ol.breadcrumb').addClass('more');
    }

    megaMenuTop();

};